const SiteStyleStatus = {
    namespaced: true,
    state: {
      inputStyle: 0,
    },
    mutations: {
      SET_INPUT_STYLE_STATUS(state, status) {
        state.inputStyle = status;
      },
    },
    actions: {
      setInputStyleStatus({ commit }, status) {
        commit('SET_INPUT_STYLE_STATUS', status);
      },
    },
    getters: {
      getInputStyle(state) {
        return state.inputStyle;
      },
    },
  };
  
  export default SiteStyleStatus;