<template>
  <v-app id="inspire">
    <Header />
    <v-main class="grey lighten-3 pt-0">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
  data () {
    return {
    }
  }
}
</script>

<style>
.sidebar {
  /* height: 100%; */
  padding-top: 60px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px;
  height: 100%;
}
.sidebar>h1 {
  color: #878787;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
