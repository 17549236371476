<template>
  <div class="how-to-use-page">
    <h1 class="heading">使い方</h1>
    <div class="video-container">
      <video controls controlsList="nodownload" class="responsive-video" @contextmenu.prevent>
        <source src="/media/how_to_use_inputSite.mp4" type="video/mp4">
        お使いのブラウザはビデオタグをサポートしていません。
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: "../../public/media/how_to_use_inputSite.mp4'",
    };
  }
};
</script>

<style>
.how-to-use-page {
  display: flex;
  flex-direction: column;
}
.video-container {
  width: 100%; /* コンテナの幅を設定 */
  display: flex;
  justify-content: center; /* 横方向の中央揃え */
}
.responsive-video {
  max-width: 960px; /* 最大幅を100%に設定 */
  height: auto; /* 高さを自動調整 */
}
</style>

<style scoped>
.how-to-use-page {
  margin-top: 15vh;
  margin-left: 20vw;
  margin-right: 20vw;
}

.heading {
margin-bottom: 20px;;
}
.heading2 {
  margin-bottom: 5vh;
  text-align: center;
  font-size: 20px;
}
.paragraph {
margin-top: 20px;
font-size: 17px;
}
.custom-carousel {
  max-width: 60vw; 
  margin: auto;
}

</style>