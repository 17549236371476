<template>
    <v-btn
    :size="iconButtonSize"
    elevation="0"
    color="transparent"
    style="padding: 0;"
    :disabled="disabled"
    >
        <v-icon
        size=20px
        color="success"
        icon="mdi-help-circle-outline"></v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'ButtonIcon',
    data () {
        return {
            iconButtonSize: "40px 40px",
        }
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
};
</script>

<style scoped>
</style>
