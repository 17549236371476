<template>
  <div class="contact-page">
    <h1 class="heading">コンタクト</h1>
    <h2 class="heading2">お問い合わせ、ご質問、改善のご要望、誤りのご指摘などございましたら、下記へご連絡ください。</h2>
    <p class="paragraph">株式会社アーキロイド</p>
    <p class="paragraph">info[at]heightlimit.archiroid.com</p>

  </div>
</template>

<style scoped>
.contact-page {
  margin-top: 20vh;
  margin-left: 20vw;
  margin-right: 20vw;
}

.heading {
margin-bottom: 10vh;
}
.heading2 {
  margin-top: 6vh;
  margin-bottom: 58px;
  font-size: 20px;
}
.paragraph {
margin-top: 20px;
font-size: 17px;
}

</style>