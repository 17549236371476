import demo_input from './demo_input.json'; // input段階でobjects（配列）状態

export default {
    methods: {
        fetchWithFallback(url, options) {
            return fetch(url, options)
                .then(response => {
                    if (!response.ok) {
                        console.log("error response", response)
                        throw new Error('Fetch failed, trying fallback.');
                    }
                    return response;
                })
                .catch(error => {
                    return error;
                });
        },
        postInputJsonToCalc(enabled,isUpload) {
            if(enabled) {
                if(isUpload) {
                    alert('isupload : ', isUpload)
                } else {
                    // json 作成
                    const boundaryList = this.DrawPolygonStatusToJson();
                    var postDataJSON = {
                        "inputAID":null,
                        "buildingInputData":null,
                        "environment_id":null,
                        "boundaryList": boundaryList,
                        "zoning":this.$store.state.ConditionStatus.areaZoneType,
                        "floorAreaRatio":this.$store.state.ConditionStatus.floorAreaRatio,
                        "buildingCoverageRatio":this.$store.state.ConditionStatus.buildingCoverageRatio, 
                        "kjyc":this.$store.state.ConditionStatus.zoneHighriseDwellings,
                        "absoluteHeight":this.$store.state.ConditionStatus.limitedHeight,
                        "bdsbs":this.$store.state.ConditionStatus.zoneLaw3_1,
                        "rhlv":this.$store.state.ConditionStatus.limitedAngle_road,
                        "h56_1_2":this.$store.state.ConditionStatus.zoneLaw56_1,
                        "h56_1_2_i":this.$store.state.ConditionStatus.zoneLaw56_2,
                        "h56_1_2_ni":this.$store.state.ConditionStatus.limitedAngle_neighbour,
                        "ssRegulation":this.$store.state.ConditionStatus.caluculateReverseShading,
                        "flg_hokkaido":this.$store.state.ConditionStatus.flg_hokkaido,
                        "h56n2_1_b4_ni":this.$store.state.ConditionStatus.shadingZoneTime,
                        "h56n2_1_b4_23":this.$store.state.ConditionStatus.shadingHeight,
                        "h56n2_1_b4_4":this.$store.state.ConditionStatus.targetBuilding,
                        "glHeight":this.$store.state.ConditionStatus.glHeight,
                        "residentialRatio":this.$store.state.ConditionStatus.floorAreaPercentage
                    };
                    console.log('postDataJSON');
                    console.log(postDataJSON);
                    const postDataString = JSON.stringify(postDataJSON)
                    
                    var apiurlHttps = this.$httpsCalcFromInput;
                    var options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: postDataString
                    };
                    
                    this.fetchWithFallback(apiurlHttps, options)
                        .then(response => response.json())
                        .then(data => {
                                console.log('Success:', data, data.id)
                                window.open(this.$httpsViewerOutput + data.id)
                                }
                            )
                        .catch(error => console.error('Error with fallback request:', error));
                }
            }
        },
        DrawPolygonStatusToJson() {
            console.log("DrawPolygonStatusToJson");
            const vertices = this.$store.state.DrawPolygonStatus.polygonVertices[0]
            var edges = []
            console.log(vertices)
            console.log(this.$store.state.DrawPolygonStatus)
            
            for (let si = 0; si < vertices.length-1; si++) {
                let ei = si+1;
                console.log(si, ei)
                const line = {
                    "sPt" : {
                        "name": "",
                        "x": vertices[si][0],
                        "y": vertices[si][1],
                        "z": 0.0,
                        "id": ""
                    },
                    "ePt" : {
                        "name": "",
                        "x": vertices[ei][0],
                        "y": vertices[ei][1],
                        "z": 0.0,
                        "id": ""
                    },
                    "Info":null,
                    "roadWidth":this.$store.state.DrawPolygonStatus.roadWidths[si],
                    "roadLength":this.$store.state.DrawPolygonStatus.roadLengths[si],
                    "roadHeight1":this.$store.state.DrawPolygonStatus.roadHeights1[si],
                    "roadHeight2":this.$store.state.DrawPolygonStatus.roadHeights2[si],
                    "adjacentGL":this.$store.state.DrawPolygonStatus.neighborHeights[si],
                    "retreatDistanceUpper":this.$store.state.DrawPolygonStatus.setbacks[si],
                    "retreatDistance":this.$store.state.DrawPolygonStatus.setbacks2[si],
                    "openSpaceDistance":this.$store.state.DrawPolygonStatus.openSpaceDistances[si]
                };
                edges.push(line);
            }
            return edges;
        }
    },
  };