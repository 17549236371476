<template> 
  <TabBar /> 
  <BaseMap />
</template>

<script>

import BaseMap from '@/components/Map.vue';
import TabBar from '@/components/Tab/TabBar.vue';

export default {
  name: 'Home',
  components: {
    BaseMap,
    TabBar
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
