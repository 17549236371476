<template>
    <div v-if="localTableWidthMini">
        <h1 class="sidebar_subhead" style="margin-top:18px;">敷地ライン</h1>
        <v-table
            fixed-header
            height="360px"
            class="bartable"
        >
            <thead>
                <tr>
                    <th class="text-left" style="width:20px; padding: 0px 8px; font-size:14px">
                    {{ no }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ shiten }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ shuten }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ nagasa }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ haba }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ shitenTakasa }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ shutenTakasa }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ tonariTakasa }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ kenchikuButsuKoutai }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ kenchikuKoutai }}
                    </th>
                    <th class="text-left" style="padding: 0px 10px; font-size:14px;">
                    {{ kouen }}
                    </th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="item in items" :key="item.no" style="font-size:12px;" @mouseover="selectedLine(item.no)">
                <td style="padding: 0px 8px; width:20px;">{{ item.no + 1 }}</td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                    <p>緯度：{{ item.start.latitude }}</p>
                    <p>経度：{{ item.start.longitude }}</p>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                    <p>緯度：{{ item.start.latitude }}</p>
                    <p>経度：{{ item.start.longitude }}</p>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                    <p>{{Number(item.length).toLocaleString()}}mm</p>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                  <div class="input-with-suffix">
                    <input 
                      :value="getProp(item.no,this.roadWidths)"
                      @input="updateRoadWidth(item.no, $event.target.value)"
                      class="custom-input" />
                    <span class="suffix-text">mm</span>
                  </div>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                  <div class="input-with-suffix">
                    <input 
                      :value="getProp(item.no,this.roadHeights1)"
                      @input="updateRoadHeight1(item.no, $event.target.value)"
                      class="custom-input" />
                    <span class="suffix-text">mm</span>
                  </div>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                  <div class="input-with-suffix">
                    <input 
                      :value="getProp(item.no,this.roadHeights2)"
                      @input="updateRoadHeight2(item.no, $event.target.value)"
                      class="custom-input" />
                    <span class="suffix-text">mm</span>
                  </div>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                  <div class="input-with-suffix">
                    <input 
                      :value="getProp(item.no,this.neighborHeights)"
                      @input="updateNeighborHeight(item.no, $event.target.value)"
                      class="custom-input" />
                    <span class="suffix-text">mm</span>
                  </div>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                  <div class="input-with-suffix">
                    <input 
                      :value="getProp(item.no,this.setbacks)"
                      @input="updateSetBack(item.no, $event.target.value)"
                      class="custom-input" />
                    <span class="suffix-text">mm</span>
                  </div>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                  <div class="input-with-suffix">
                    <input 
                      :value="getProp(item.no,this.setbacks2)"
                      @input="updateSetBack2(item.no, $event.target.value)"
                      class="custom-input" />
                    <span class="suffix-text">mm</span>
                  </div>
                </td>
                <td class="text-left" style="padding: 0px 10px; white-space: nowrap;">
                  <div class="input-with-suffix">
                    <input 
                      :value="getProp(item.no,this.openSpaceDistances)"
                      @input="updateOpenSpaceDistance(item.no, $event.target.value)"
                      class="custom-input" />
                    <span class="suffix-text">mm</span>
                  </div>
                </td>
            </tr>
            </tbody>
        </v-table>
    </div>
    <div v-else>
    </div>
</template>
  
<script>
import postInputJsonToCalc from '@/mixins/postInputJsonToCalc';
import { mapState, mapActions,mapGetters } from 'vuex';

export default {
name: 'table-form-points',
components: {
},
watch: {
    polygonVertices(newValue) {
      this.setCalActiveFlg(false);
      this.setRoadsInfo();
      this.setItems(newValue);
      this.localTableWidthMini = newValue.length > 0;
    },
    //TODO 同じ振る舞いをしている変数、関数は今後まとめましょう
    roadWidths: {
      deep: true,
      handler(newValue) {
        this.setCalActivate();
      }
    },
    roadHeights1: {
      deep: true,
      handler(newValue) {
        this.setCalActivate();
      }
    },
    roadHeights2: {
      deep: true,
      handler(newValue) {
        this.setCalActivate();
      }
    },
    neighborHeights: {
      deep: true,
      handler(newValue) {
        this.setCalActivate();
      }
    },
    setbacks: {
      deep: true,
      handler(newValue) {
        this.setCalActivate();
      }
    },
    setbacks2: {
      deep: true,
      handler(newValue) {
        this.setCalActivate();
      }
    },
    openSpaceDistances: {
      deep: true,
      handler(newValue) {
        this.setCalActivate();
      }
    }
},
data () {
    return {
        items: [
        ],
        no: 'No.',
        shiten: '始点',
        shuten: '終点',
        nagasa: '長さ',
        haba: '道路幅員',
        shitenTakasa: '始点路面中心高さ',
        shutenTakasa: '終点路面中心高さ',
        tonariTakasa: '隣地地盤面高さ',
        kenchikuButsuKoutai: '建築物後退距離',
        kenchikuKoutai: '建築後退距離',
        kouen: '公園等距離',
        headers: [
          {
            title: 'No.',
            align: 'start',
            sortable: false,
            key: 'no',
          },
          { title: '始点', key: 'start' },
          { title: '終点', key: 'end' },
          { title: '長さ', key: 'length' },
          { title: '道路幅員', key: 'width' },
          { title: '始点路面中心高さ', key: 'roadH1' },
          { title: '終点路面中心高さ', key: 'roadH2' },
          { title: '隣地地盤面高さ', key: 'neighborH' },
          { title: '建築物後退距離', key: 'setback' },
          { title: '建築後退距離', key: 'setback2' },
          { title: '公園等距離', key: 'kouen' },
        ],
        selected:'',
        localTableWidthMini: this.tableWidthMini,
    }
},
created: function () {
},
computed: {
    ...mapState('DrawPolygonStatus', {
      calActivate: (state) => state.calActivate,
      polygonVertices: (state) => state.polygonVertices,
      selectedLineNo: (state) => state.selectedLineNo,
      roadLengths: (state) => state.roadLengths,
      roadWidths: (state) => state.roadWidths,
      roadHeights1: (state) => state.roadHeights1,
      roadHeights2: (state) => state.roadHeights2,
      neighborHeights: (state) => state.neighborHeights,
      setbacks: (state) => state.setbacks,
      setbacks2: (state) => state.setbacks2,
      openSpaceDistances: (state) => state.openSpaceDistances,
    }),
    ...mapGetters('ConditionStatus', ['getAreaZoneOverFlg']),
    isPolygon(){
      return this.polygonVertices.length > 0;
    },
},
props: {
    tableWidthMini: {
        type: Boolean
    }
},
mounted: function () {
  if(this.isPolygon){this.setItems(this.polygonVertices)}
},
methods: {
    ...mapActions('DrawPolygonStatus',['setCalActiveFlg', 'setSelectedLineNo',
    'setRoadLengths', 'setRoadWidths', 'setRoadHeights1', 'setRoadHeights2', 'setNeighborHeights', 'setSetbacks', 'setSetbacks2', 'setOpenSpaceDistances']),
    setItems(newValue) {
        this.items = []
        if (newValue.length !== 0 && newValue !== undefined && newValue !== null) {
            let digit = 7;
            const vertices = newValue[0]
            for (let index = 0; index < vertices.length-1; index++) {
                const element = vertices[index]
                const element1 = vertices[index + 1]
                this.items.push(
                    {
                        'no': index,
                        'start': {
                            'longitude': element[0].toPrecision(digit),
                            'latitude': element[1].toPrecision(digit)
                        },
                        'end': {
                            'longitude': element1[0].toPrecision(digit),
                            'latitude': element1[1].toPrecision(digit)
                        },
                        'length': this.roadLengths[index],
                    }
                )
            }
        }
    },
    selectedLine(no){
        this.setSelectedLineNo(Number(no));
    },
    setCalActivate() {
      if(this.isPolygon && !this.getAreaZoneOverFlg){
        if (this.roadWidths.some(value => this.isNotNull(value)) && 
            this.polygonVertices[0].length > 2) {
              this.setCalActiveFlg(true);
              }
          // else{
          //   // エラー表示
          // }
        }
    },
    setRoadsInfo(){
      // 道路データを初期化
      this.setRoadLengths([]);
      this.setRoadWidths([]);
      this.setRoadHeights1([]);
      this.setRoadHeights2([]);
      this.setNeighborHeights([]);
      this.setSetbacks([]);
      this.setSetbacks2([]);
      this.setOpenSpaceDistances([]);

      // 全てのroadlengthを登録
      if(this.isPolygon){
        const lengths = [];
        for (let i = 0; i < this.polygonVertices[0].length-1; i++){
          const { point } = require('@turf/helpers')
          const distance = require('@turf/distance').default
          
          const from = point([this.polygonVertices[0][i][0], this.polygonVertices[0][i][1]]); 
          const to = point([this.polygonVertices[0][i+1][0], this.polygonVertices[0][i+1][1]]); 
          const options = { units: 'kilometers' }; // degrees, radians, miles, kilometersが指定可能

          const result = Math.round(distance(from, to, options) * 1000 * 1000);
          lengths.push(result);
        }
        this.setRoadLengths(lengths)
        
        // 道路分の空リストを作成
        this.setRoadWidths(new Array(this.roadLengths.length).fill(''))
        this.setRoadHeights1(new Array(this.roadLengths.length).fill(''))
        this.setRoadHeights2(new Array(this.roadLengths.length).fill(''))
        this.setNeighborHeights(new Array(this.roadLengths.length).fill(''))
        this.setSetbacks(new Array(this.roadLengths.length).fill(''))
        this.setSetbacks2(new Array(this.roadLengths.length).fill(''))
        this.setOpenSpaceDistances(new Array(this.roadLengths.length).fill(''))
      }
    },
    isNull(v) {
      if(v === null || v === undefined || v===''){
        return true
      }
      else{return false}
    },
    isNotNull(v){
      return !this.isNull(v)
    },
    isAllNotNull(val){
      return val.every(v => this.isNotNull(v));
    },
    getProp(itemNo,list){
      const value = list[itemNo];
      return value === '' ? '0' : value;
    },
    updateRoadWidth(itemNo, newValue) {
      this.setRoadWidths({ no: itemNo, value: newValue });
    },
    updateRoadHeight1(itemNo, newValue) {
      this.setRoadHeights1({ no: itemNo, value: newValue });
    },
    updateRoadHeight2(itemNo, newValue) {
      this.setRoadHeights2({ no: itemNo, value: newValue });
    },
    updateNeighborHeight(itemNo, newValue) {
      this.setNeighborHeights({ no: itemNo, value: newValue });
    },
    updateSetBack(itemNo, newValue) {
      this.setSetbacks({ no: itemNo, value: newValue });
    },
    updateSetBack2(itemNo, newValue) {
      this.setSetbacks2({ no: itemNo, value: newValue });
    },
    updateOpenSpaceDistance(itemNo, newValue) {
      this.setOpenSpaceDistances({ no: itemNo, value: newValue });
    },
}
}
</script>

<style scoped>
.v-table td {
    background: #f0f0f0;
}
.v-table tr:nth-child(odd) td {
    background: #fff;
}
.v-table tr:hover td {
    background-color: rgba(33, 191, 117, 0.1);
}
.tab-row {
  margin: 0;
}
.row-center {
  justify-content: center;
  align-items: center;
}
.col-center {
  padding: 0px 5px;
}
button.v-btn[disabled] {
  opacity: 0.6;
}
.site-condition{
  padding: 0px;
}
.custom-input {
  font-size: 12px;
  width: 60px;
  text-align: right;
  border: 1px solid #ccc; 
  background-color: #ffffff;
  border-radius: 4px;
}
</style>
