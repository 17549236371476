<template>
  <div v-if="fill">
    <v-btn
      :color="color" 
      :disabled="disabled"
      :height="h"
      :width="w"
      :prepend-icon="prependIcon"
      :append-icon="appendIcon">
      <p>{{ text }}</p>
    </v-btn>
  </div>
  <div v-else
    class="unfill">
    <v-btn
      :color="color" 
      :disabled="disabled"
      :height="h"
      :width="w"
      :prepend-icon="prependIcon"
      :append-icon="appendIcon"
      variant="outlined">
      <p>{{ text }}</p>
    </v-btn>
  </div>
</template>

  
<script>
  export default {
    name: 'ButtonDefault',
    data () {
      return {
        h: 40,
        w: 160,
      }
    },
    props: {
      color: {
        type: String,
        default: 'white',
      },
      text: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      fill: {
        type: Boolean,
        default: true
      },
      prependIcon: {
        type: String,
        default: ''
      },
      appendIcon: {
        type: String,
        default: ''
      },
    },
    mounted: function () {
    },
  };
  </script>
  
  <style scoped>
  .unfill {
    background-color: #FFFFFF;
  }
.custom-btn.v-btn--outlined {
  border-width: 1px;
}
.custom-btn.v-btn--outlined:hover {
  border-color: color;
}
  </style>
  