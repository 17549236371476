<template>
    <div>
        <v-row style="padding: 100px 90px 30px 90px;" justify="center" align="top">
            <v-col cols="740" v-for="item in items" :key="item">
                <CardInputConditions
                :title="item.title"
                :text="item.text"></CardInputConditions>
            </v-col>
        </v-row>
    </div>
    <ButtonDefault
        color="success"
        text="戻る"
        @click="clickBack"
        :fill=false
    ></ButtonDefault>
    <ButtonDefault
        color="success"
        text="計算する"
        @click="postInputJsonToCalc($store.state.ConditionStatus.filledFlg,false)"
        :disabled="!$store.state.ConditionStatus.filledFlg"
        :fill=true
    ></ButtonDefault>
</template>

<script>
    import ButtonDefault from '@/components/Button/ButtonDefault.vue';
    import CardInputConditions from '@/components/Card/CardInputConditions.vue';
    import postInputJsonToCalc from '@/mixins/postInputJsonToCalc.js';
    export default {
        name: 'ConditionInput',
        components: {
            ButtonDefault,
            CardInputConditions
        },
        props: {
            color: {
                type: String,
                default: 'primary',
            },
            text: {
                type: String,
                default: '',
            },
            icon: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            fill: {
                type: Boolean,
                default: true
            }
        },
        mixins: [postInputJsonToCalc],
        data () {
            return {
                items: [
                    {
                        title: '基本情報',
                        text: 'aaaa',
                        publishedAt: '2016-04-10'},
                    {
                        title: '絶対高さ',
                        text: '1111 Doe',
                        publishedAt: '2016-04-10'},
                    {
                        title: '隣地斜線',
                        text: '2222 Doe',
                        publishedAt: '2222-04-10'},
                    {
                        title: '道路斜線',
                        text: '3333 Doe',
                        publishedAt: '2016-04-10'},
                    {
                        title: '建築',
                        text: '4444 Doe',
                        publishedAt: '2016-04-10'},
                    {
                        title: '逆日影',
                        text: '5555 Doe',
                        publishedAt: '2016-04-10'},
                ]
            }
        },
        mounted: function () {
        },
        methods: {
            clickBack: function () {
                this.$router.push('/');
            },
            clickProceed: function () {
                console.log("進むボタン");
                this.$router.push('form-confirm');
            }
        }
    };
</script>

<style scoped>
/* ここに必要なスタイルを追加 */
</style>