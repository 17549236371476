import { createStore } from 'vuex';
import DrawPolygonStatus from './modules/DrawPolygonStatus.js'
import ConditionStatus from './modules/ConditionStatus.js'
import SiteStyleStatus from './modules/SiteStyleStatus.js'

export default createStore({
    modules: {
        DrawPolygonStatus,
        ConditionStatus,
        SiteStyleStatus
    },
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    }
  });
