<template>
  <div class="formatsample-page">
  <h1 class="heading">フォーマットとサンプル</h1>
  <div>
      <h2 class="heading2">フォーマット</h2>
      <h3 class="heading3">①敷地情報</h3>
      <a href="/downloadFiles/Environment.xlsx" download="Environment.xlsx" class="custom-link">敷地情報</a>
      <h3 class="heading3">②敷地形状</h3>
      <p class="paragraph">以下のどちらかをお選びください。</p>
      <a href="/downloadFiles/Site.xlsx" download="Site.xlsx" class="custom-link">敷地形状（Excel）</a>
      <a href="/downloadFiles/Site.3dm" download="Site.3dm" class="custom-link">敷地形状（Rhinoceros）</a>
      <h2 class="heading2">サンプル１：基本的な計算（約１分）</h2>
      <h3 class="heading3">①敷地情報</h3>
      <a href="/downloadFiles/Environment_Sample1.xlsx" download="Environment_Sample1.xlsx" class="custom-link">敷地情報</a>
      <h3 class="heading3">②敷地形状</h3>
      <p class="paragraph">以下のどちらかをお選びください。</p>
      <a href="/downloadFiles/Site_Sample1.xlsx" download="Site_Sample1.xlsx" class="custom-link">敷地形状（Excel）</a>
      <a href="/downloadFiles/Site_Sample1.3dm" download="Site_Sample1.3dm" class="custom-link">敷地形状（Rhinoceros）</a>
      <h2 class="heading2">サンプル２：各種緩和を適用した計算（約１分３０秒）</h2>
      <h3 class="heading3">①敷地情報</h3>
      <a href="/downloadFiles/Environment_Sample2.xlsx" download="Environment_Sample2.xlsx" class="custom-link">敷地情報</a>
      <h3 class="heading3">②敷地形状</h3>
      <p class="paragraph">以下のどちらかをお選びください。</p>
      <a href="/downloadFiles/Site_Sample2.xlsx" download="Site_Sample2.xlsx" class="custom-link">敷地形状（Excel）</a>
      <a href="/downloadFiles/Site_Sample2.3dm" download="Site_Sample2.3dm" class="custom-link">敷地形状（Rhinoceros）</a>
      <h2 class="heading2">サンプル３：４面道路に囲まれた計算（約４０秒）</h2>
      <h3 class="heading3">①敷地情報</h3>
      <a href="/downloadFiles/Environment_Sample3.xlsx" download="Environment_Sample3.xlsx" class="custom-link">敷地情報</a>
      <h3 class="heading3">②敷地形状</h3>
      <p class="paragraph">以下のどちらかをお選びください。</p>
      <a href="/downloadFiles/Site_Sample3.xlsx" download="Site_Sample3.xlsx" class="custom-link">敷地形状（Excel）</a>
      <a href="/downloadFiles/Site_Sample3.3dm" download="Site_Sample3.3dm" class="custom-link">敷地形状（Rhinoceros）</a>
      <p class="end"></p>
  </div>
</div>
</template>

<script>

</script>

<style scoped>
.formatsample-page {
  margin-top: 20vh;
  margin-left: 20vw;
  margin-right: 20vw;
}

.heading {
margin-bottom: 8vh; 
}
.heading2 {
  margin-top: 6vh;
  margin-bottom: 4vh;
}
.heading3 {
  margin-top: 4vh;
  /* margin-bottom: 3px; */
}
.paragraph {
margin-left: 20px;
margin-bottom: 10px;
}
.custom-link {
color: #000000;
padding-left: 20px; 
}
.end{
  margin-top: 10vh;
}
</style>