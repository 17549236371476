import { createRouter, createWebHistory } from 'vue-router'

import Home from "@/views/Home.vue";
import AboutService from "@/views/AboutService.vue";
import HowToUse from "@/views/HowToUse.vue";
import FormatSample from "@/views/FormatSample.vue";
import SitePolicy from "@/views/SitePolicy.vue";
import Contact from "@/views/Contact.vue";
import ConditionInput from "@/views/ConditionInput.vue";
import FormConfirm from "@/views/FormConfirm.vue";

const routes = [
    { path: '/', name: 'Home',  component: Home },
    { path: '/about-service', name: 'AboutService',  component: AboutService },
    { path: '/how-to-use', name: 'HowToUse',  component: HowToUse },
    { path: '/format-sample', name: 'FormatSample',  component: FormatSample },
    { path: '/site-policy', name: 'SitePolicy',  component: SitePolicy },
    { path: '/contact', name: 'Contact',  component: Contact },
    { path: '/condition-input', name: 'ConditionInput',  component: ConditionInput },
    { path: '/form-confirm', name: 'FormConfirm',  component: FormConfirm },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router