<template>
    <div :style="mapWrapStyle" class="map-wrap">
      <div id="mapContainer" class="basemap"></div>
      <button style="z-index: 1; position: absolute; bottom: 20px; right: 20px;" @click="toggleLayerVisibility">レイヤーの表示/非表示を切り替える</button>
    </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { mapState, mapActions, mapGetters } from 'vuex';
import centroid from '@turf/centroid';
import bbox from '@turf/bbox';
import { popScopeId } from "vue";

export default {
  name: "BaseMap",
  data() {
    return {
      map: null,
      draw: null,
      popup: null,
      layerVisible: true,
      isPolygon: false,
      style1: 'mapbox://styles/archiroid/clbkvrzi7001914p0nv6uvbps',
      style2: 'mapbox://styles/mapbox/satellite-v9',
      center: [139.753, 35.6844],
      currentStyle: '',
      layerId: 'states-layer',
      fixedAreaTypes: [],
      currentSiteInfo: {},
      mapWrapStyle: {
          width: '40vw',//0 < this.polygonVertices.length ? '30vw' : '70vw', // polygonが作成済みの場合は30vw、それ以外は70vw
          height: 'calc(100vh)' // 高さは変更しない
        }
    };
  },
  watch: {
    inputAreaStatus(newValue, oldValue) {
      this.activatePolygonDraw(newValue, oldValue);
    },
    polygonVertices(newValue) {
      if(Object.keys(newValue).length === 0) {
        this.clearPolygonOnMap()
      }
    },
    selectedLineNo(newValue) {
      this.selectLineMark()
    },
    inputStyle(newValue) {
      this.setWrapStyle()
    }
  },
  mounted() {
    this.setWrapStyle(),
    this.initializeMap();
  },
  methods:{
    setWrapStyle() {
      var returns;
      if (this.inputStyle === 0) {
        returns = {
          width: '40vw',//0 < this.polygonVertices.length ? '30vw' : '70vw', // polygonが作成済みの場合は30vw、それ以外は70vw
          height: 'calc(100vh)' // 高さは変更しない
        };
      } else {
        returns =  {
          width: '0vw',//0 < this.polygonVertices.length ? '30vw' : '70vw', // polygonが作成済みの場合は30vw、それ以外は70vw
          height: 'calc(100vh)' // 高さは変更しない
        };
      }
      this.mapWrapStyle = returns;
      },
    initializeMap(){
      // mapbox, mapbox drawのインポート
      mapboxgl.accessToken = 'pk.eyJ1IjoiYXJjaGlyb2lkIiwiYSI6ImNsYWptdm1yYzBkdzMzcXA4MHRkYWdiNngifQ.HTiupB3pqTj6tOkVFR5nMQ'
      
      if(0 < this.polygonVertices.length){
        this.center = this.getPolygonCentroid;
      }

      this.map = new mapboxgl.Map({
        container: "mapContainer",
        style: this.style1, 
        center: this.center,
        zoom: 14
        })
      this.currentStyle = this.style1;
      
      this.map.on('load', () => {
        this.mapIsLoaded = true;

        this.addVectorTileSource();
        this.addStandardButtons();
        this.addButtonListener();
        
        if(0 < this.polygonVertices.length){
          this.drawPolygonOnMap(this.polygonVertices[0],true);
          this.drawLineMark();
        }
      });
    },
    //追加部分↓
    ...mapActions('DrawPolygonStatus', ['setInputAreaStatus','setPolygonVertices','setPolygonCentroid', 'setInputCancelFlg']),
    ...mapActions('ConditionStatus', ['setAreaZoneOverFlg','setAreaZoneType','setFloorAreaRatio','setBuildingCoverageRatio','setFlgHokkaido']),
    ...mapGetters('DrawPolygonStatus',['getPolygonCentroid']),
    async activatePolygonDraw(flg_now) { 
      // Drawコントロールの有効/無効を切り替える
      if (flg_now) { // OFF->ON
        // 用途地域情報の配列を編集
        this.currentSiteInfo = {"areaTypes":[],"areaNames":[],"yousekis":[],"kenpeis":[]};
        this.map.addControl(this.draw);
      } else { // ON -> OFF
        if (this.inputCancelFlg) {
          // 座標情報は変更せずにcacncelFlgを元に戻して終了
          this.setInputCancelFlg(false)
        } else {
          // フィックスされたポリゴンを作成して追加する
          const getall = await this.draw.getAll();
          var features = null;
          if (getall && getall.length > 0) {
            features = getall
          } else if (getall.features && getall.features.length > 0) {
            features = getall.features
          }
          // 座標情報を抽出
          let polygonfeatures = features.filter(feature => feature.geometry.type === 'Polygon');
          console.log(polygonfeatures)
          var coordinate = polygonfeatures.map(feature => feature.geometry.coordinates);
          coordinate = coordinate[0][0];
          if (coordinate.length > 4) {
            coordinate = coordinate.filter(n => n !== coordinate[coordinate.length-2]);
            this.drawPolygonOnMap(coordinate,false)
            this.fixedAreaTypes = this.currentSiteInfo["areaTypes"];

            if(this.fixedAreaTypes.every(v => v === this.fixedAreaTypes[0])){
              this.setAreaZoneType(this.fixedAreaTypes[0]);
              this.setAreaZoneOverFlg(false);
              this.setFloorAreaRatio(this.currentSiteInfo["yousekis"][0]);
              this.setBuildingCoverageRatio(this.currentSiteInfo["kenpeis"][0]);
            }
            else{
              this.setAreaZoneOverFlg(true);
            }

            this.setFlgHokkaido(this.currentSiteInfo["areaNames"][0] === '北海道');
            this.drawLineMark()
          } 
        }
        this.map.removeControl(this.draw);
      }
    },
    drawPolygonOnMap(coordinate,reload) {
      const newCoordinate = [coordinate];
      // ポリゴンを描画する処理
      const polygon = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: newCoordinate
        }
      };
      const source = this.map.getSource('polygon');
      if (source) {
        source.setData(polygon);
      } else {
        this.map.addSource('polygon', {
          type: 'geojson',
          data: polygon
        });
        this.map.addLayer({
          id: 'polygon',
          type: 'fill',
          source: 'polygon',
          layout: {},
          paint: {
            'fill-color': '#ff0000',
            'fill-opacity': 0.5
          }
        });
      }
      if(!reload){
        this.setPolygonVertices(newCoordinate);
      }

      // 中心座標の設定
      const newCenter = centroid(polygon);
      this.setPolygonCentroid(newCenter.geometry.coordinates);
      this.center = newCenter.geometry.coordinates;

      const polyBbox = bbox(polygon);
      this.map.fitBounds(polyBbox,{padding: 100});
    },
    clearPolygonOnMap() { // ポリゴンを削除する
      const source = this.map.getSource('polygon');
      if (source) {
        const polygon = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [[]]
          }
        };
        source.setData(polygon);
        this.currentSiteInfo = {"areaTypes":[],"areaNames":[],"yousekis":[],"kenpeis":[]};
        this.setAreaZoneOverFlg(false);
      }
      this.map.removeLayer('circle-layer');
      this.map.removeLayer('text-layer');
    },
    selectLineMark(){
      this.map.setPaintProperty('circle-layer', 'circle-color', [
            'match',
            ['get', 'title'],
            String(this.selectedLineNo+1), '#21BF75', // 赤色に変更
            '#FFFFFF' // その他は白色のまま
        ]);
      this.map.setPaintProperty('text-layer', 'text-color', [
          'match',
          ['get', 'title'],
          String(this.selectedLineNo+1), '#FFFFFF', // 赤色に変更
          '#21BF75' // その他は白色のまま
      ]);
    },
    handleMapClick(event) {
      if (this.inputAreaStatus) {
        const lngLat = event.lngLat;
        const coordinates = [[lngLat.lng, lngLat.lat], [lngLat.lng, lngLat.lat], [lngLat.lng, lngLat.lat]];
        const polygon = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates]
          }
        };
        this.draw.add(polygon); // ポリゴンを描画する処理
      }
    },
    drawLineMark(){
      // Lineのマーク作成
      const locations = [];
      for (let i = 0; i < this.polygonVertices[0].length-1; i++){
        const ptX = this.polygonVertices[0][i][0] + (this.polygonVertices[0][i+1][0] - this.polygonVertices[0][i][0])/2;
        const ptY = this.polygonVertices[0][i][1] + (this.polygonVertices[0][i+1][1] - this.polygonVertices[0][i][1])/2;
        
        let myLocation = {coords:[ptX,ptY], title:String(i+1)};
        locations.push(myLocation)
        }
      // 複数の座標を使用して、geojsonデータを作成
      const geojsonData = {
          'type': 'FeatureCollection',
          'features': locations.map(loc => ({
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': loc.coords
            },
            'properties': {
                'title': loc.title
            }
        }))
      };

      // geojsonソースを地図に追加
      if(this.map.getSource('circle-source')){
        this.map.removeLayer('circle-layer');
        this.map.removeLayer('text-layer');
        this.map.removeSource('circle-source');
      }

      this.map.addSource('circle-source', {
          'type': 'geojson',
          'data': geojsonData
      });
      // geojsonソースに基づいてcircleタイプのレイヤーを地図に追加
      this.map.addLayer({
          'id': 'circle-layer',
          'type': 'circle',
          'source': 'circle-source',
          'minzoom': 16,
          'paint': {
              'circle-radius': 10,
              'circle-color': [
              'match',
              ['get', 'title'],
              '1', '#21BF75',
              '#FFFFFF'
              ],
              'circle-stroke-color': '#21BF75',
              'circle-stroke-width': 2
          }
      });
      this.map.addLayer({
        'id': 'text-layer',
        'type': 'symbol',
        'source': 'circle-source',
        'minzoom': 16,
        'layout': {
            'text-field': ['get', 'title'],
            'text-size': 16,
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold']
        },
        'paint': {
          'text-color': [
              'match',
              ['get', 'title'],
              '1', '#FFFFFF',
              '#21BF75'
              ],
        }
      });
    },
    setCurrentSiteInfo(property){
      this.currentSiteInfo["areaNames"].push(property.A29_002);
      this.currentSiteInfo["areaTypes"].push(property.A29_005);
      this.currentSiteInfo["kenpeis"].push(property.A29_006);
      this.currentSiteInfo["yousekis"].push(property.A29_007);
    },
    addVectorTileSource(){
      if (!this.map.getSource('custom-tiles')) {
        this.map.addSource('custom-tiles', {
          'type': 'vector',
          tiles: ['https://heightlimit-zone.s3.amazonaws.com/Japan3/{z}/{x}/{y}.pbf'],
          minzoom: 10,
          maxzoom: 14
        });
      }
      if (!this.map.getLayer(this.layerId)) {
        this.map.addLayer({
          'id': this.layerId,
          'type': 'fill',
          'source': 'custom-tiles',
          'source-layer': 'states',
          'layout': {},
          'paint': {
            'fill-opacity': 0.4,
            'fill-color': ['match', ['get', "A29_005"],
              '第一種低層住居専用地域', '#FF1744',
              '第二種低層住居専用地域', '#F50057',
              '第一種中高層住居専用地域', '#D500F9',
              '第二種中高層住居専用地域', '#651FFF',
              '第一種住居地域', '#3D5AFE',
              '第二種住居地域', '#2979FF',
              '準住居地域', '#00E5FF',
              '近隣商業地域', '#1DE9B6',
              '商業地域', '#00E676',
              '準工業地域', '#76FF03',
              '工業地域', '#C6FF00',
              '工業専用地域', '#FFEA00',
              '田園住居地域', '#FFC400',
              '不明', '#FF9100',
              '#FF3D00'        // それ以外のポリゴンは青色に
            ]
          }
        });
        
        this.map.on('click', (e) => {
            // Set `bbox` as 1px reactangle area around clicked point.
            if (this.inputAreaStatus){
              const bbox = [
              [e.point.x - 1, e.point.y - 1],
              [e.point.x + 1, e.point.y + 1]
              ];
              // Find features intersecting the bounding box.
              const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
              layers: ['states-layer']
              });
              this.setCurrentSiteInfo(selectedFeatures[0].properties)
            }
            });
        
        //mouse over
        this.map.on('mousemove', this.layerId, (e) => {
          const features = this.map.queryRenderedFeatures(e.point, { layers: [this.layerId] });
          if (!features.length) {
              return;
          }
          if (this.popup) {
                this.popup.remove();
            }
          this.popup = new mapboxgl.Popup({ closeButton: false })
              .setLngLat(e.lngLat)
              .setHTML(e.features[0].properties.A29_005)
              .addTo(this.map);
      });

      this.map.on('mouseleave', this.layerId, () => {
          this.map.getCanvas().style.cursor = '';
          if(this.popup){
            this.popup.remove();
          }
      });
      }
      if(!this.layerVisible){
        this.map.setLayoutProperty(this.layerId, 'visibility', 'none');
      }
    },
    toggleLayerVisibility() {
      if (this.layerVisible) {
        this.map.setLayoutProperty(this.layerId, 'visibility', 'none');
        this.layerVisible = false;
      } else {
        console.log(this.layerVisible)
        this.map.setLayoutProperty(this.layerId, 'visibility', 'visible');
        this.layerVisible = true;
        console.log(this.layerVisible)
      }
    },
    addButtonListener(){
      const button = document.querySelector('button');
      button.removeEventListener('click', this.toggleLayerVisibility);
      button.addEventListener('click', this.toggleLayerVisibility);
    },
    addStandardButtons(){
      const navControl = new mapboxgl.NavigationControl();
      this.map.addControl(navControl, 'top-right')

      const geocoder = new MapboxGeocoder({
              accessToken: mapboxgl.accessToken,
              mapboxgl: mapboxgl,
              placeholder: '住所で検索',
              reverseGeocode: true,
              zoom: 19
          });
      const navControlContainer = navControl._container;
      const geocoderEl = geocoder.onAdd(this.map);

      navControlContainer.parentNode.insertBefore(geocoderEl, navControlContainer);

      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
        polygon: false,
        trash: false
        },
        defaultMode: 'draw_polygon'
      });
      this.map.on('click', this.handleMapClick)
    }
  },
computed: {
    ...mapState('DrawPolygonStatus', {
      inputAreaStatus: (state) => state.inputAreaStatus,
      inputCancelFlg: (state) => state.inputCancelFlg,
      polygonVertices: (state) => state.polygonVertices,
      polygonCentroid: (state) => state.polygonCentroid,
      selectedLineNo: (state) => state.selectedLineNo,
    }),
    ...mapState('ConditionStatus', {
      areaZoneType: (state) => state.areaZoneType,
    }),
    ...mapGetters('DrawPolygonStatus',['getPolygonCentroid']),
    ...mapState('SiteStyleStatus', {
      inputStyle: (state) => state.inputStyle,
    }),
  },
};
</script>

<style>
@import '~mapbox-gl/dist/mapbox-gl.css';

.map-wrap {
  position: relative;
}

.basemap {
  position: relative;
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-top-right {
  display: flex;
  flex-direction: row;
  align-items: top; /* 子要素を縦方向の中心に配置 */
  margin-top: 65px;
}

.mapboxgl-ctrl-geocoder {
  order: -1; /* ジオコーダーを左側に移動 */
  margin-right: 10px; /* ナビゲーションコントロールとの間隔 */
  height: 30px;
}
</style>