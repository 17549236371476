<template>
    <div class="policy-page">
    <h1 class="heading">サイトポリシー</h1>
    <div>
        <h2 class="heading2">第1条 本ポリシーについて</h2>
        <ol>
        <li>このサイトポリシー（以下「本ポリシー」といいます）は、本サイトおよび本サービスを利用する際の利用条件などを定めたものです。本サイトおよび本サービスを利用した場合は、本ポリシーに同意したものとみなされます。</li>
        <li>当社は、相当の事由があると判断した場合には、当社の判断により、本ポリシーを予告なく変更することができるものとします。</li>
        <li>変更後の本ポリシーは、別段の定めがない限り、本サイト上に表示した時点で効力を生じるものとします。また、変更後の本ポリシーの効力が生じた後に、本サイトおよび本サービスを利用した場合は、変更後の本ポリシーに同意したものとみなされます。</li>
        <li>本ポリシーに示される権利を行使しない場合、または実施しない場合でも、当社が当該権利を放棄するものではありません。</li>
        <li>本ポリシーの全部、または一部が法令に基づいて無効と判断された場合であっても、当該規定の無効部分以外の部分およびその他の規定は有効であるものします。また、本ポリシーの一部が特定の利用者との間で無効とされた場合、または取り消された場合であっても、本ポリシーはその他の利用者との間では有効であるものとします。</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第2条 定義</h2>
        <p>本ポリシーにおいて使用する用語の意義は、以下に定めるとおりとします。</p>
        <ol class="custom-ol">
        <li>このサイトポリシー（以下「本ポリシー」といいます）は、本サイトおよび本サービスを利用する際の利用条件などを定めたものです。本サイトおよび本サービスを利用した場合は、本ポリシーに同意したものとみなされます。</li>
        <li>当社は、相当の事由があると判断した場合には、当社の判断により、本ポリシーを予告なく変更することができるものとします。</li>
        <li>変更後の本ポリシーは、別段の定めがない限り、本サイト上に表示した時点で効力を生じるものとします。また、変更後の本ポリシーの効力が生じた後に、本サイトおよび本サービスを利用した場合は、変更後の本ポリシーに同意したものとみなされます。</li>
        <li>本ポリシーに示される権利を行使しない場合、または実施しない場合でも、当社が当該権利を放棄するものではありません。</li>
        <li>本ポリシーの全部、または一部が法令に基づいて無効と判断された場合であっても、当該規定の無効部分以外の部分およびその他の規定は有効であるものします。また、本ポリシーの一部が特定の利用者との間で無効とされた場合、または取り消された場合であっても、本ポリシーはその他の利用者との間では有効であるものとします。</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第3条 権利の帰属</h2>
        <ol>
        <li>本サイトおよび本サービスに関する知的財産権は、原則として当社に帰属します。ただし、当社がライセンスを受けて掲載している第三者の著作物の著作権は、その限りではありません。</li>
        <li>本サービスに利用者が入力する入力情報に関わる知的財産権は当社には帰属しません。</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第4条 入力情報の利用</h2>
        <ol>
        <li>入力情報は、本サービス内での利用のほか、本サービスの改善、または当社の他サービス開発のために利用する可能性があります。</li>
        <li>入力情報を上記の目的以外で利用することはありません。ただし、以下の各号に該当する場合に限り、第三者に対して入力情報を提供する可能性があります。</li>
            <ol class="custom-ol">
            <li>入力情報を提供をしなければ、人の生命、身体または財産に重大な損害が生じるおそれがある場合</li>
            <li>法令およびこれに準じる規定により、入力情報の提供が求められた場合</li>
            </ol>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第5条 禁止事項</h2>
        <p>利用者は、本サイトおよび本サービスの利用にあたり、以下の各号のいずれかに該当する行為を行ってはならないものとします。</p>
        <ol class="custom-ol">
        <li>当社、またはその他の第三者が社会的信用を失うおそれのある行為</li>
        <li>当社、またはその他の第三者が経済的損失を被るおそれのある行為</li>
        <li>当社、またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利を侵害するおそれのある行為</li>
        <li>本サービスの運営を妨害するおそれある行為</li>
        <li>本サービスに関連するネットワークまたはシステムなどに不正にアクセスする行為、または不正にアクセスを試みる行為</li>
        <li>本サービスのネットワーク、またはシステムなどに過度な負荷をかける行為</li>
        <li>本サービスを通じ、次の各号のいずれかに該当するファイルをアップロード、または当社に送信する行為
            <ul class="inner-list">
                <li>コンピューター・ウィルス</li>
                <li>有害なコンピュータ・プログラム</li>
                <li>その他、前各号のいずれかに該当するおそれがあると当社が判断するファイル、または当社が不適切と判断するファイル</li>
            </ul>
        </li>
        <li>本サービスによって提供される機能を複製、修正、転載、改変、変更、リバースエンジニアリング、逆アセンブル、逆コンパイル、翻訳、または解析する行為</li>
        <li>本サービスの全部または一部を営利目的で、使用方法を問わず利用する行為</li>
        <li>本サイト上で掲載する本サービス利用に関するルールに抵触する行為</li>
        <li>法令、または公序良俗に反する行為</li>
        <li>前各号の行為を直接、または間接に惹起する行為、または容易にする行為</li>
        <li>その他、前各号のいずれかに該当するおそれがあると当社が判断する行為、または当社が不適切と判断する行為</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第6条 登録</h2>
        <p>本サービスは登録が不要なサービスです。当社から本サービスを通じて利用者にアカウント登録や個人情報の入力を求めることはありません。</p>
    </div>
    <div>
        <h2 class="heading2">第7条 サービスの停止等</h2>
        <ol>
        <li>当社は、以下の各号のいずれかに該当する場合には、当社の判断で予告なく、本サービスの全部、または一部を停止、中断することができるものとします。</li>
            <ol class="custom-ol">
            <li>本サイトおよび本サービスの点検、または保守作業を緊急または定期的に行う場合</li>
            <li>コンピュータまたは通信回線が事故により停止した場合</li>
            <li>天災地変などの不可抗力、戦争、暴動、内乱、法令の改廃制定、公権力による命令処分、騒乱、労働争議などにより本サービスの運営が困難になった場合</li>
            <li>その他、当社が相当の事由があると判断した場合</li>
            </ol>
        <li>当社は、本条に基づいて当社が行った措置により利用者、または第三者が被った損害について、一切の責任を負わないものとします。</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第8条 サービスの内容変更、終了</h2>
        <ol>
        <li>当社は、当社の都合により、事前の通知なしに本サービスの内容を変更し、または提供を終了することができるものとします。</li>
        <li>当社は、当社の都合により、本サービスの運営を第三者に委託、または本サービスを第三者に譲渡することができるものとします。</li>
        <li>当社は、本条に基づいて当社が行った措置により利用者、または第三者が被った損害について、一切の責任を負わないものとします。</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第9条 免責</h2>
        <ol>
        <li>当社は、本サービスの利用により発生した利用者の損害については、一切の賠償責任を負いません。</li>
        <li>利用者が、本サービスを利用することにより、その他の第三者に対して損害を与えた場合、利用者は自己の責任と費用においてこれを賠償するものとします。</li>
        <li>当社は、本サービスの出力情報を含む本サイトにおいて提供する情報について、利用者の目的に適合すること、完全性、正確性、確実性、有効性、有用性、利用可能性、及び最新性を有することを保証いたしません。</li>
        <li>当社は、本サービスの出力情報を含む本サイトにおいて提供する情報について、利用者に適用ある法令に適合することを保証いたしません。</li>
        <li>当社は、本サービスの機能が中断されることはないこと、エラーがないこと、または欠点が修正されること、このサイトまたはサーバーにコンピュータ・ウィルスおよびその他の有害物が無いことを保証いたしません。</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第10条 リンク</h2>
        <ol>
        <li>本サイトへのリンクの設定は、以下の各号のいずれかに該当する場合にはお断りします。</li>
            <ol class="custom-ol">
            <li>当社、またはその他の第三者が社会的信用を失うおそれがある場合</li>
            <li>当社、またはその他の第三者が経済的損失を被るおそれがある場合</li>
            <li>当社、またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利を侵害するおそれがある場合</li>
            <li>本サイトのオリジナルデザインを変更するような改変や、フレーム内で本サイトまたは本サービスが展開されるなど、当社のサービスであることが不明確となり、第三者に誤解を与えるおそれがある場合</li>
            <li>本サービスの運営を妨害するおそれがある場合</li>
            <li>法令または公序良俗に反する場合</li>
            <li>その他、前各号のいずれかに該当するおそれがあると当社が判断する場合、または当社が不適切と判断する場合</li>
            </ol>
        <li>当社が保有するロゴやマークなどの登録商標を無断で用いてリンク設定することは認めておりません。</li>
        <li>リンクに関連した賠償、苦情などに関して、当社は一切の責任を負いません。</li>
        </ol>
    </div>
    <div>
        <h2 class="heading2">第11条 自己責任の原則</h2>
        <p>本サイトおよびに本サービスの利用は利用者の自由意志によるものとし、利用に関していかなる責任も利用者にあるものとします。</p>
    </div>
    <div>
        <h2 class="heading2">第12条【準拠法および管轄裁判所】</h2>
        <ol>
        <li>本サイトおよび本サービスの利用、本ポリシーの解釈および適用は、他に別段の定めの無い限り準拠法は日本法とします。</li>
        <li>本サイトおよび本サービスの利用、本ポリシーに起因する、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
        </ol>
    </div>
    <p class="end">株式会社アーキロイド</p>
  </div>
</template>

<script>

</script>

<style scoped>
.policy-page {
    margin-top: 20vh;
    margin-left: 20vw;
    margin-right: 20vw;
}

.heading {
  margin-bottom: 8vh; 
}
.heading2 {
    margin-top: 6vh;
    margin-bottom: 4vh;
}
ol {
    margin-top: 20px;
    padding-left: 20px;
}
ol li {
    margin-bottom: 10px;
    padding-left: 20px;
}
.custom-ol {
  list-style: none;
  counter-reset: custom-counter; 
}

.custom-ol > li {
  counter-increment: custom-counter;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
}

.custom-ol > li::before {
  content: "(" counter(custom-counter) ")";
  position: absolute;
  left: 0;
}
.inner-list {
  list-style-type: none;
  padding-left: 20px; 
}

.inner-list li::before {
  content: '・';
  padding-right: 10px; 
}
.paragraph {
  margin-top: 20px;
}
.paragraph .custom-link {
  color: #000000;
}

.end{
    margin-top: 15vh;
    margin-bottom: 10vh;
    text-align: right;
}
</style>