import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';

const app = createApp(App);


// グローバルプロパティの設定
const backendURL_https = 'https://testapi.heightlimit.archiroid.com';

app.config.globalProperties.$http = (url, opts) => fetch(url, opts);
app.config.globalProperties.$httpsCalcFromInput = backendURL_https + '/api/HightLimit/calc';
app.config.globalProperties.$httpsViewerOutput = 'https://viewer.heightlimit.archiroid.com/output/id=';



app.use(router);
app.use(store);
app.use(vuetify);

app.mount('#app');
  