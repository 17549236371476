<template>
    <ButtonDefault
        prependIcon="mdi-file-upload-outline"
        text="アップロード"
        color="success"
        :fill=false
        :disabled=false
        @click="uploadFunc()"/>
</template>

<script>
import ButtonDefault from './ButtonDefault.vue';
export default {
    name: 'button-upload',
    components: {
        ButtonDefault
    },
    methods: {
        uploadFunc: function () {
            console.log('!upload clicked!');
        }
    }
};
</script>
  
<style scoped>
</style>
  