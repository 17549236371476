import "vuetify/styles";
import { createVuetify, ThemeDefinition } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#21BF75',
    secondary: '#03DAC6',
    error: '#F60707',
    themagray: '#878787',
    themalightgray: 'rgb(236,236,236,0.5)',
  }
}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
  }
});

export default vuetify;
