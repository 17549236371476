<template>
    <ButtonIcon
    icon="mdi-trash-can-outline"
    color="success"
    :disabled=true
    @click="trashFunc()"/>
</template>

<script>
import ButtonIcon from './ButtonIcon.vue';
export default {
    name: 'button-upload',
    components: {
        ButtonIcon
    },
    methods: {
        trashFunc: function () {
            console.log('!trash clicked!');
        }
    }
};
</script>
  
<style scoped>
</style>
  