const ConditionStatus = {
    namespaced: true,
    state: {
      overAreaZoneFlg: false, // 用途地域が跨った場合：True
      filledFlg: false, //すべて必要項目が埋まった場合：True
      
      areaZoneType: null, // 用途地域 
      floorAreaRatio: '', // 指定容積率(%)
      buildingCoverageRatio:'0', // 指定建ぺい率 HeightLimit_Controllerでは使用してないので、マニュアル値
      zoneHighriseDwellings: false, // 高層住居誘導地区である

      limitedHeight: '0', // 絶対高さ
      
      zoneLaw56_1: false, // 法第56条1項2号のカッコ書き規定に基づき指定する区域
      zoneLaw56_2: false, // 法第56条1項2号イのカッコ書き規定に基づき指定する区域
      limitedAngle_neighbour: '', // 用途地域"指定なし"の場合の、隣地斜線制限の角度数値
      
      zoneLaw3_1: false, // 法別表第３備考３の規定に基づき指定する区域
      limitedAngle_road: '', // 用途地域"指定なし"の場合の、道路斜線制限の角度数値
      
      glHeight: '0', // 地盤面高さ(mm)
      floorAreaPercentage: '', // 延べ床面積に対する住宅の用途に供する部分の床面積の割合(%)
      
      caluculateReverseShading: false, // 逆日影計算を行う
      flg_hokkaido: false, // 北海道適応
      shadingZoneTime: '', // 法第56条の2の1項の別表第4（に）に関するカッコ書き規定に基づき指定する日影時間区分（1又は2又は3
      shadingHeight:'', // 法第56条の2の1項の別表第4二三に関するカッコ書き規定に基づき指定する高さ数値（4,000mm又は6,500mm）
      targetBuilding: '' // 法第56条の2の1項の別表第4四に関するカッコ書き規定に基づき指定する対象建築物（イ又はロ
    },
    mutations: {
      SET_AREA_ZONE_OVER_FLG(state, status) {
        state.overAreaZoneFlg = status;
      },
      SET_FILLED_FLG(state, status){
        state.filledFlg = status;
      },
      SET_AREA_ZONE_TYPE(state, status) {
        state.areaZoneType = status;
      },
      SET_FLOOR_AREA_RATIO(state, status) {
        state.floorAreaRatio = status;
      },
      SET_BUILDING_COVERAGE_RATIO(state, status) {
        state.buildingCoverageRatio = status;
      },
      SET_ZONE_HIGHRISE_DWELLINGS(state, status) {
        state.zoneHighriseDwellings = status;
      },
      SET_LIMITED_HEIGHT(state, status) {
        state.limitedHeight = status;
      },
      SET_ZONE_LAW56_1(state, status) {
        state.zoneLaw56_1 = status;
      },
      SET_ZONE_LAW56_2(state, status) {
        state.zoneLaw56_2 = status;
      },
      SET_LIMITED_ANGLE_NEIGHBOUR(state, status) {
        state.limitedAngle_neighbour = status;
      },
      SET_ZONE_LAW3_1(state, status) {
        state.zoneLaw3_1 = status;
      },
      SET_LIMITED_ANGLE_ROAD(state, status) {
        state.limitedAngle_road = status;
      },
      SET_GL_HEIGHT(state, status) {
        state.glHeight = status;
      },
      SET_FLOOR_AREA_PERCENTAGE(state, status) {
        state.floorAreaPercentage = status;
      },
      SET_CALUCULATE_REVERSE_SHADING(state, status) {
        state.caluculateReverseShading = status;
      },
      SET_FLG_HOKKAIDO(state, status) {
        state.flg_hokkaido = status;
      },
      SET_SHADING_ZONE_TIME(state, status) {
        state.shadingZoneTime = status;
      },
      SET_SHADING_HEIGHT(state, status) {
        state.shadingHeight = status;
      },
      SET_TARGET_BUILDING(state, status) {
        state.targetBuilding = status;
      },
    },
    actions: {
      setAreaZoneOverFlg({ commit }, status) {
        commit('SET_AREA_ZONE_OVER_FLG', status);
      },
      setFilledFlg({ commit }, status) {
        commit('SET_FILLED_FLG', status);
      },
      setAreaZoneType({ commit }, status) {
        commit('SET_AREA_ZONE_TYPE', status);
      },
      setFloorAreaRatio({ commit }, status) {
        commit('SET_FLOOR_AREA_RATIO', status);
      },
      setBuildingCoverageRatio({ commit }, status) {
        commit('SET_BUILDING_COVERAGE_RATIO', status);
      },
      setZoneHighriseDwellings({ commit }, status) {
        commit('SET_ZONE_HIGHRISE_DWELLINGS', status);
      },
      setLimitedHeight({ commit }, status) {
        commit('SET_LIMITED_HEIGHT', status);
      },
      setZoneLaw56_1({ commit }, status) {
        commit('SET_ZONE_LAW56_1', status);
      },
      setZoneLaw56_2({ commit }, status) {
        commit('SET_ZONE_LAW56_2', status);
      },
      setLimitedAngle_neighbour({ commit }, status) {
        commit('SET_LIMITED_ANGLE_NEIGHBOUR', status);
      },
      setZoneLaw3_1({ commit }, status) {
        commit('SET_ZONE_LAW3_1', status);
      },
      setLimitedAngle_road({ commit }, status) {
        commit('SET_LIMITED_ANGLE_ROAD', status);
      },
      setGlHeight({ commit }, status) {
        commit('SET_GL_HEIGHT', status);
      },
      setFloorAreaPercentage({ commit }, status) {
        commit('SET_FLOOR_AREA_PERCENTAGE', status);
      },
      setCaluculateReverseShading({ commit }, status) {
        commit('SET_CALUCULATE_REVERSE_SHADING', status);
      },
      setFlgHokkaido({ commit }, status) {
        commit('SET_FLG_HOKKAIDO', status);
      },
      setShadingZoneTime({ commit }, status) {
        commit('SET_SHADING_ZONE_TIME', status);
      },
      setShadingHeight({ commit }, status) {
        commit('SET_SHADING_HEIGHT', status);
      },
      setTargetBuilding({ commit }, status) {
        commit('SET_TARGET_BUILDING', status);
      },
    },
    getters: {
      getAreaZoneOverFlg(state) {
        return state.overAreaZoneFlg;
      },
      getAreaZoneType(state) {
        return state.areaZoneType;
      },
      getFloorAreaRatio(state) {
        return state.floorAreaRatio;
      },
      getZoneHighriseDwellings(state) {
        return state.zoneHighriseDwellings;
      },
      getLimitedHeight(state) {
        return state.limitedHeight;
      },
      getZoneLaw56_1(state) {
        return state.zoneLaw56_1;
      },
      getZoneLaw56_2(state) {
        return state.zoneLaw56_2;
      },
      getLimitedAngle_neighbour(state) {
        return state.limitedAngle_neighbour;
      },
      getZoneLaw3_1(state) {
        return state.zoneLaw3_1;
      },
      getLimitedAngle_road(state) {
        return state.limitedAngle_road;
      },
      getGlHeight(state) {
        return state.glHeight;
      },
      getFloorAreaPercentage(state) {
        return state.floorAreaPercentage;
      },
      getCaluculateReverseShading(state) {
        return state.caluculateReverseShading;
      },
      getShadingZoneTime(state) {
        return state.shadingZoneTime;
      },
      getShadingHeight(state) {
        return state.shadingHeight;
      },
      getTargetBuilding(state) {
        return state.targetBuilding;
      },
    },
  };
  
  export default ConditionStatus;