<template>
  <v-card 
    color="themalightgray"
    elevation=1
    width="100%"
    height="165"
    style="padding: 40px 20px 20px 20px;">
    
      <v-file-input
        id="fileInputBox"
        class="fileinput elevation-0"
        variant="solo"
        :accept="fileAccept"
        :label="labelText"
        prepend-icon
        density="compact"
        show-size></v-file-input>

      <ButtonTrash style="position: absolute; bottom: 20px; right: 20px;" />
      <ButtonUpload style="position: absolute; bottom: 20px; right: 60px;" />
    </v-card>
  </template>

<script>
import ButtonUpload from '../Button/ButtonUpload.vue';
import ButtonTrash from '../Button/ButtonTrash.vue';
export default {
  name: 'file-upload-card',
  components: {
    ButtonUpload,
    ButtonTrash
  },
  watch: {
  },
  data () {
    return {
      cardWidth: '100%',
      labelText: 'inputfile.xlsx',
      fileAccept: 'image/*',
      iconButtonSize: "30px 40px",
      errorFlg: true,
      errorMessage:"敷地が用途地域をまたがっている為、計算ができません",
    }
  },
  created: function () {
  },
  computed: {
  },
  props: {
    // title: { type: String, default: 'none', required: true },
  },
  mounted: function () {
  },
  methods: {
    uploadFunc: function () {
      console.log('!upload clicked!');
    }
  }
}
</script>

<style scoped>
.v-field .v-field--appended .v-field--center-affix .v-field--variant-solo .v-theme--myCustomLightTheme .v-locale--is-ltr {
  height: 30px;
}
.v-field__field {
  height: 30px;
}
.fileinput .v-label {
  font-size: 16px;
}
#fileInputBox{
  height: 30px;
}
.v-card-text.filesample_text {
    font-size: 14px;
    font-weight: bold;
    color: #21BF75;
    padding: 25px 0px 0px 0px;
}
.v-card-text.error_text {
    font-size: 12px;
    font-weight: bold;
    color: #F60707;
    padding: 20px 0px 0px 0px;
}
</style>
