<template>
    <v-card 
      color="themalightgray"
      elevation=0
      height="100%"
      class="custom-card">
      <v-card-title style="font-size: 20px;" class="custom-text-color">{{ title }}</v-card-title>
    <v-card-text>
      <!-- 基本情報の場合のコンテンツ -->
      <template v-if="title === '基本情報'">
        <!-- 用途地域 -->
        <p class="header1-text custom-text-color">用途地域</p>
        <div v-if="this.flg_AreaZoneType" class="input-text-Inputed input-long flex-container">
          <div v-html="this.areaZoneType"></div>
        </div>
        <v-select 
          v-else 
          v-model="selectedAreaZoneType" 
          :items="options"
          @update:modelValue="updateField('setAreaZoneType', selectedAreaZoneType)"
          class="input-text input-long custom-select"
          :label="selectedAreaZoneType ? '' : '選択してください'"
        ></v-select>
        <!-- 指定容積率 -->
        <p class="header1-text custom-text-color">指定容積率(%)</p>
        <div v-if="this.flg_FloorAreaRatio" class="input-text-Inputed input-short flex-container">
          <div v-html="this.floorAreaRatio"></div>
        </div>
        <input 
          v-else type="number" 
          v-model="inputedFloorAreaRatio" 
          @input="updateField('setFloorAreaRatio', $event.target.value)"
          class="input-text input-short flex-container" 
          placeholder="例）100"
        >
        <!-- 建ぺい率 -->
        <p class="header1-text custom-text-color">建ぺい率(%)</p>
        <div v-if="this.flg_buildingCoverageRatio" class="input-text-Inputed input-short flex-container">
          <div v-html="this.buildingCoverageRatio"></div>
        </div>
        <input 
          v-else type="number" 
          v-model="inputedbuildingCoverageRatio" 
          @input="updateField('setBuildingCoverageRatio', $event.target.value)"
          class="input-text input-short flex-container" 
          placeholder="例）100"
        >
        <!-- 高層住居誘導地区である -->
        <p class="header1-text custom-text-color">高層住居誘導地区である</p>
        <v-checkbox 
          v-model="isZoneHighriseDwellings"
          @input="updateField('setZoneHighriseDwellings', $event.target.checked)"
          class="custom-checkbox"
          label="あてはまる場合はチェック" />
          <div v-if="cautionZoneHighriseDwellings()" class="caution-message">
            ※指定、且つ、住宅部分が2/3以上の場合、斜線適用距離と角度が変わります
          </div>
      </template>
      <!-- 絶対高さの場合のコンテンツ -->
      <template v-else-if="title === '絶対高さ'">
        <div style="height: 20px;"></div>
        <v-radio-group 
          inline 
          v-model="selectedLimitedHeight" 
          @change="updateField('setLimitedHeight', selectedLimitedHeight)"
        >
          <v-radio v-if="!isNeedLimitedHeight" label="適用しません" value="0"></v-radio>
          <template v-else>
            <v-radio label="10,000mm" value="10000"></v-radio>
            <div style="width: 50px;"></div>
            <v-radio label="12,000mm" value="12000"></v-radio>
          </template>
        </v-radio-group>
      </template>
      <template v-else-if="title === '隣地斜線'">
        <!-- 隣地斜線の場合のコンテンツ -->
        <p class="header1-text custom-text-color">法第56条1項2号のカッコ書き規定に基づき指定する区域</p>
        <v-checkbox 
          v-model="isZoneLaw56_1"
          @input="updateField('setZoneLaw56_1', $event.target.checked)"
          class="custom-checkbox"
          label="あてはまる場合はチェック" />
        <div v-if="cautionZoneLaw56_1()" class="caution-message">
          ※指定の場合、隣地斜線不適用となります
        </div>
        <p class="header1-text custom-text-color">法第56条1項2号イのカッコ書き規定に基づき指定する区域</p>
        <v-checkbox 
          v-model="isZoneLaw56_2"
          @input="updateField('setZoneLaw56_2', $event.target.checked)"
          class="custom-checkbox"
          label="あてはまる場合はチェック" />
        <div v-if="cautionZoneLaw56_2()" class="caution-message">
        ※指定の場合、斜線角度数値は2.5になります
        </div>
        <p class="header1-text custom-text-color">隣地斜線制限の角度数値</p>
        <v-radio-group inline
          v-model="selectedLimitedAngle_neighbour" 
          @change="updateField('setLimitedAngle_neighbour', selectedLimitedAngle_neighbour)"
        >
          <v-radio v-if="!isUnspecifiedType" label="自動算定" value=''></v-radio>
          <template v-else>
            <v-radio label="1.25" value='1.25'></v-radio>
            <div style="width: 50px;"></div>
            <v-radio label="1.50"  value='1.50'></v-radio>
          </template>
        </v-radio-group>
      </template>
      <template v-else-if="title === '道路斜線'">
        <!-- 道路斜線の場合のコンテンツ -->
        <p class="header1-text custom-text-color">法別表第３備考３の規定に基づき指定する区域</p>
        <v-checkbox 
          v-model="isZoneLaw3_1"
          @input="updateField('setZoneLaw3_1', $event.target.checked)"
          class="custom-checkbox"
          label="あてはまる場合はチェック" />
          <div v-if="cautionZoneLaw3_1()" class="caution-message">
            ※指定の場合、斜線適用距離と角度が変わります
          </div>
        <p class="header1-text custom-text-color">道路斜線制限の角度数値</p>
        <v-radio-group inline
          v-model="selectedLimitedAngle_road" 
          @change="updateField('setLimitedAngle_road', selectedLimitedAngle_road)"
        >
          <v-radio v-if="!isUnspecifiedType" label="自動算定" value=''></v-radio>
          <template v-else>
            <v-radio label="1.25" value='1.25'></v-radio>
            <div style="width: 50px;"></div>
            <v-radio label="1.50"  value='1.50'></v-radio>
          </template>
        </v-radio-group>
      </template>
      <template v-else-if="title === '建築'">
        <!-- 建築のコンテンツ -->
        <p class="header1-text custom-text-color">地盤面高さ(mm)</p>
        <div class="text-field-container">
          <input 
            v-model="inputedGlHeight" 
            @input="updateField('setGlHeight', $event.target.value)"
            class="input-text input-short"
            type="number"
            placeholder="例）100"
          />
        </div>
        <p class="header1-text custom-text-color">延べ床面積に対する住宅の用途に供する部分の床面積の割合(%)</p>
        <div class="text-field-container">
          <input 
            v-model="inputedFloorAreaPercentage" 
            @input="updateField('setFloorAreaPercentage', $event.target.value)"
            class="input-text input-short"
            type="number"
            placeholder="例）100"
          />
          <div v-if="this.zoneHighriseDwellings && cautionZoneHighriseDwellings()" class="error-message">
            ※入力必須項目です
          </div>
        </div>
      </template>
      <template v-else-if="title === '逆日影'">
        <!-- 逆日影の場合のコンテンツ -->
        <p class="header1-text custom-text-color">逆日影計算を行う</p>
        <v-checkbox 
          v-model="isCaluculateReverseShading"
          @input="updateField('setCaluculateReverseShading', $event.target.checked)"
          class="custom-checkbox"
          label="あてはまる場合はチェック"/>
        <div v-if="cautionCaluculateReverseShading()" class="caution-message">
          ※逆日影計算をする場合、チェックを入れてください
        </div>
        <div v-if="isInptedValue(this.zoneHighriseDwellings)" class="error-message">
          ※高層住居誘導地区のため、日影規制は対象区域外となります
        </div>
        <template v-if="this.isCaluculateReverseShading" >
          <p class="header1-text custom-text-color">日影時間区分</p>
          <v-radio-group inline
            v-model="selectedShadingZoneTime" 
            @change="updateField('setShadingZoneTime', selectedShadingZoneTime)"
          >
            <template v-if="isLimitedShadingZonTime">
              <v-radio label="1" value='1'></v-radio>
              <div style="width: 50px;"></div>
              <v-radio label="2"  value='2'></v-radio>
            </template>
            <template v-else>
              <v-radio label="1" value='1'></v-radio>
              <div style="width: 50px;"></div>
              <v-radio label="2"  value='2'></v-radio>
              <div style="width: 50px;"></div>
              <v-radio label="3"  value='3'></v-radio>
            </template>
          </v-radio-group>
          <div v-if="this.flg_hokkaido" class="caution-message">
            ※道の区域のため、日影時間が変わります
          </div>
          <template v-if="isNeedShadingHeight">
            <p class="header1-text custom-text-color">高さ数値</p>
          <v-radio-group inline
            v-model="selectedShadingHeight" 
            @change="updateField('setShadingHeight', selectedShadingHeight)"
          >
              <v-radio label="4,000mm"       value='4000'></v-radio>
              <v-radio label="6,500mm"       value='6500'></v-radio>
          </v-radio-group>
          </template>
          <template v-if="isUnspecifiedType">
            <p class="header1-text custom-text-color">対象建築物</p>
            <v-radio-group inline
              v-model="selectedTargetBuilding" 
              @change="updateField('setTargetBuilding', selectedTargetBuilding)">
                <v-radio label="イ"       value='イ'></v-radio>
                <v-radio label="ロ"       value='ロ'></v-radio>
            </v-radio-group>
          </template>
        </template>
      </template>
    </v-card-text>
    </v-card>
</template>
  
  <script>
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'input-condition-card',
    components: {
    },
    watch: {
      isNeedLimitedHeight(newValue) { this.selectedLimitedHeight = newValue ? "10000" : "0";},
      isUnspecifiedType(newValue){
        this.selectedLimitedAngle_neighbour = newValue ? '1.25' : '';
        this.selectedLimitedAngle_road = newValue ? '1.25' : '';
      },
      isCaluculateReverseShading(newValue){
        this.selectedShadingZoneTime = newValue ? '1' : '';
        this.selectedShadingHeight = newValue ? '4000':'';
        this.selectedTargetBuilding = newValue ? 'イ':'';
      },
      isNeedShadingHeight(newValue){this.selectedShadingHeight = newValue ? '4000':'';},
    },
    props: {
            title: {
                type: String,
                default: '',
            },
            text: {
                type: String,
                default: '',
            }
    },
    data () {
      return {
        cardWidth: '100%',
        labelText: 'inputfile.xlsx',
        fileAccept: 'image/*',
        iconButtonSize: "30px 40px",
        errorFlg: true,
        flg_AreaZoneType: false,
        selectedAreaZoneType: null,
        options: ['第一種低層住居専用地域',
                  '第二種低層住居専用地域',
                  '第一種中高層住居専用地域',
                  '第二種中高層住居専用地域',
                  '第一種住居地域',
                  '第二種住居地域',
                  '準住居地域',
                  '近隣商業地域',
                  '商業地域',
                  '準工業地域',
                  '工業地域',
                  '工業専用地域',
                  '田園住居地域',
                  '指定なし',],
        flg_FloorAreaRatio: false,
        inputedFloorAreaRatio: null,
        flg_buildingCoverageRatio: false,
        inputedbuildingCoverageRatio: null,
        isZoneHighriseDwellings: false,
        selectedLimitedHeight: "0",
        isZoneLaw56_1: false,
        isZoneLaw56_2: false,
        selectedLimitedAngle_neighbour: '',
        isZoneLaw3_1: false,
        selectedLimitedAngle_road: '',
        inputedGlHeight: '0',
        inputedFloorAreaPercentage: null,
        isCaluculateReverseShading: false,
        selectedShadingZoneTime: '',
        selectedShadingHeight: '',
        selectedTargetBuilding: '',
        noInputValue: [
          v => !!v || '値を入力してください。' // vが空の場合、エラーメッセージを返す
        ],
      }
    },
    computed:{
    ...mapState('ConditionStatus', {
      filledFlg: (state) => state.filledFlg,
      areaZoneType: (state) => state.areaZoneType,
      floorAreaRatio: (state) => state.floorAreaRatio,
      buildingCoverageRatio: (state) => state.buildingCoverageRatio,
      zoneHighriseDwellings: (state) => state.zoneHighriseDwellings,
      limitedHeight: (state) => state.limitedHeight,
      zoneLaw56_1: (state) => state.zoneLaw56_1,
      zoneLaw56_2: (state) => state.zoneLaw56_2,
      limitedAngle_neighbour: (state) => state.limitedAngle_neighbour,
      zoneLaw3_1: (state) => state.zoneLaw3_1,
      limitedAngle_road: (state) => state.limitedAngle_road,
      glHeight: (state) => state.glHeight,
      floorAreaPercentage: (state) => state.floorAreaPercentage,
      caluculateReverseShading: (state) => state.caluculateReverseShading,
      flg_hokkaido: (state) => state.flg_hokkaido,
      shadingZoneTime: (state) => state.shadingZoneTime,
      shadingHeight: (state) => state.shadingHeight,
      targetBuilding: (state) => state.targetBuilding,
      }),
      isNeedLimitedHeight(){
        if(this.areaZoneType == '第一種低層住居専用地域'
        ||this.areaZoneType == '第二種低層住居専用地域'
        ||this.areaZoneType == '田園住居地域'){
          return true;
        }
        else{return false;}
      },
      isUnspecifiedType(){
        if(this.areaZoneType == '指定なし'){return true;}
        else{return false;}
      },
      isLimitedShadingZonTime(){
        if(this.areaZoneType == '第一種住居地域'
        ||this.areaZoneType == '第二種住居地域'
        ||this.areaZoneType == '準住居地域'
        ||this.areaZoneType == '近隣商業地域'
        ||this.areaZoneType == '準工業地域'){
          return true;
        }
        else{return false;}
      },
      isNeedShadingHeight(){
        if(this.areaZoneType == "第一種中高層住居専用地域"
        || this.areaZoneType == "第二種中高層住居専用地域"
        || this.areaZoneType == "第一種住居地域"
        || this.areaZoneType == "第二種住居地域"
        || this.areaZoneType == "準住居地域"
        || this.areaZoneType == "近隣商業地域" 
        || this.areaZoneType == "準工業地域"){
          return true;
        }
        else{ return false;}
      }
    },
    created(){
      this.flg_AreaZoneType = this.areaZoneType != null;
      this.flg_FloorAreaRatio = this.isInptedValue(this.floorAreaRatio);
      this.flg_buildingCoverageRatio = this.isInptedValue(this.buildingCoverageRatio);
    },
    mounted(){
    },
    methods: {
    ...mapActions('ConditionStatus', 
    ['setFilledFlg','setAreaZoneType','setFloorAreaRatio','setZoneHighriseDwellings','setLimitedHeight','setZoneLaw56_1',
    'setZoneLaw56_2','setLimitedAngle_neighbour','setZoneLaw3_1','setLimitedAngle_road','setGlHeight','setFloorAreaPercentage',
    'setCaluculateReverseShading','setShadingZoneTime','setShadingHeight','setTargetBuilding','updateField']),
    isInptedValue(value) {
      if(value == null || value == ''){return false;}
      else{return true;}
    },
    errorNoneAreaZoneType(){
      if(this.areaZoneType == "指定なし"){return true;}
      else{return false;}
    },
    cautionZoneHighriseDwellings() {
      if(this.areaZoneType == "第一種住居地域"
      || this.areaZoneType == "第二種住居地域"  
      || this.areaZoneType == "準住居地域"
      || this.areaZoneType == "近隣商業地域"
      || this.areaZoneType == "準工業地域"){return  true;
      }
      else{return false;}
    },
    cautionZoneLaw3_1() {
      if(this.areaZoneType == "第一種中高層住居専用地域"
      || this.areaZoneType == "第二種中高層住居専用地域"
      || this.areaZoneType == "第一種住居地域"
      || this.areaZoneType == "第二種住居地域"
      || this.areaZoneType == "準住居地域"){return  true;
      }
      else{return false;}
    },
    cautionZoneLaw56_1() {
      if(this.areaZoneType == "近隣商業地域"
      || this.areaZoneType == "準工業地域"
      || this.areaZoneType == "商業地域"
      || this.areaZoneType == "工業地域"
      || this.areaZoneType == "工業専用地域"){
        if (this.zoneHighriseDwellings && 66.66666666 < this.floorAreaPercentage) {
          return  true;
        }
        else{ return false;}
      }
      else{return false;}
    },
    cautionZoneLaw56_2() {
      if(this.areaZoneType == "第一種住居地域"
      || this.areaZoneType == "第二種住居地域"
      || this.areaZoneType == "準住居地域"){
        if (!this.isInptedValue(this.floorAreaRatio)) {
          if(this.floorAreaRatio <= 300){return  true;}}
        else{ return false;}
      }
      else{return false;}
    },
    cautionCaluculateReverseShading() {
      if(!this.zoneHighriseDwellings && !this.isInptedValue(this.isCaluculateReverseShading)){
        return true;
      }
      else{return false;}
    },
    cautionShadingZoneTime() {
      if(this.areaZoneType == "第一種住居地域"
      || this.areaZoneType == "第二種住居地域"
      || this.areaZoneType == "準住居地域"
      || this.areaZoneType == "近隣商業地域"
      || this.areaZoneType == "準工業地域" 
      && this.shadingZoneTime == '3'){
        return true;
      }
      else{return false;}
    },
    cautionShadingHeight() {
      if(this.caluculateReverseShading){
        if(this.areaZoneType == "第一種中高層住居専用地域"
        || this.areaZoneType == "第二種中高層住居専用地域"
        || this.areaZoneType == "第一種住居地域"
        || this.areaZoneType == "第二種住居地域"
        || this.areaZoneType == "準住居地域"
        || this.areaZoneType == "近隣商業地域" 
        || this.areaZoneType == "準工業地域"
        && !this.isInptedValue(this.shadingHeight)){
          return true;
        }
      }
      else{return false;}
    },
    updateField(moduleName, value) {
      const name = 'ConditionStatus/'+moduleName
      this.$store.dispatch(name, value);
      this.setFilledFlg(this.checkInputs());
    },
    checkInputs() {
      // 必要項目（全区域共通）
      if(this.isInptedValue(this.areaZoneType) 
      && this.isInptedValue(this.floorAreaRatio) 
      && this.floorAreaRatio != '0'
      && this.isInptedValue(this.glHeight) ){
        // 高層住居誘導地区である場合
        if(this.zoneHighriseDwellings){
          if(this.areaZoneType == "第一種住居地域"
          || this.areaZoneType == "第二種住居地域"
          || this.areaZoneType == "準住居地域"
          || this.areaZoneType == "近隣商業地域"
          || this.areaZoneType == "準工業地域"){
            if(!this.isInptedValue(this.floorAreaPercentage) || this.floorAreaPercentage == '0'){
              return false;
            }
          }
        }
        // 用途地域が指定なしの場合
        if(this.areaZoneType == "指定なし"){
          if(!this.isInptedValue(this.limitedAngle_neighbour) 
          && !this.isInptedValue(this.limitedAngle_road)){
            return false;
          }
        }
        // 逆日影計算を行う場合
        if(this.caluculateReverseShading){
          if(!this.isInptedValue(this.shadingZoneTime)){return false;}
          
          if(this.areaZoneType == "第一種中高層住居専用地域"
          || this.areaZoneType == "第二種中高層住居専用地域"
          || this.areaZoneType == "第一種住居地域"
          || this.areaZoneType == "第二種住居地域"
          || this.areaZoneType == "準住居地域"
          || this.areaZoneType == "近隣商業地域"
          || this.areaZoneType == "準工業地域"){
            if(!this.isInptedValue(this.shadingHeight)){return false;}
          }
          else if(this.areaZoneType == "指定なし"){
            if(!this.isInptedValue(this.targetBuilding)){return false;}
          }
        }
        return true;
      }
      else{ return false;}
  }
    }
  }
  </script>
  
  <style scoped>
  .custom-card {
  width: 740px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 50px;
  }
  .flex-container {
    display: flex;
    align-items: flex-start;
  }

  .basic-select{
    width: 100px;
  }
  .long-select{
    width: 280px;
    flex-shrink: 0;
  }
  .header1-text {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
}
  .custom-text-color {
  color: #1F3044;
  font-weight: bold;
  font-family: 游ゴシック, Arial;
  }
  .custom-select.v-input--density-default {
    --v-input-padding-top: 0;
  }
  .input-text {
  font-family: Arial;
  font-size: 15px;
  border: 2px solid #1F3044; 
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 5px;
  }
  .input-text-Inputed {
  font-family: Arial;
  font-size: 15px;
  border: 2px solid #1F3044; 
  border-radius: 4px;
  background-color: rgba(31,48,68,0.1);
  padding: 5px;
  }
  .input-long{
  width: 300px;
  height: 30px;
  }
  .input-short{
  width: 90px;;
  height: 30px;
  }

.custom-checkbox .v-input__slot::before {
  border-color: #1F3044; /* アウトラインの色 */
}

.custom-checkbox.theme--light .v-input--selection-controls__input {
  color: #1F3044; /* チェックボックスの色 */
}

.custom-checkbox .v-input__slot {
  font-size: 15px;
  color: #1F3044; 
}

.custom-checkbox .v-input__details {
  min-height: 0px;
}
.custom-checkbox .v-messages {
  min-height: 0px;
}

.custom-checkbox {
  --v-input-control-height: 30px;
  --v-selection-control-size: 30px;
}

.error {
  border-color: red;
  background-color:rgba(255,0,0,0.1);
}

.error-message {
  color: red;
}
.caution-message {
  color:  rgba(0,0,255,1);
}
  </style>
  