<template>
  <div class="sidebar" style="display: flex; flex-direction: column; justify-content: space-between;">
    <div>
      <div style="margin-bottom: 40px;">
      <h1 class="sidebar_subhead">敷地ライン</h1>
        <CardUpload/>
      </div>
      <div style="margin-bottom: 40px;">
        <h1 class="sidebar_subhead">敷地形状データ</h1>
        <CardUpload style="margin-bottom: 40px;" />
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end;">
      <ButtonCalculation :isUpload="true"/>
    </div>
  </div>
</template>

<script>
import CardUpload from '@/components/Card/CardUpload.vue';
import ButtonCalculation from '@/components/Button/ButtonCalculation.vue';

export default {
  name: 'tab-upload',
  components: {
    CardUpload,
    ButtonCalculation
  },
  watch: {
  },
  data () {
    return {
    }
  },
  created: function () {
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>