<template>
  <div :style="updateSidebarWidth" class="sidebar">
    <v-row class="row-center">
      <div v-if="inputAreaStatus">
        <ButtonDefault
          text="完了する"
          appendIcon="mdi-check-bold"
          color="success"
          :fill=false
          @click="toggleInputAreaStatus(false)"
          ></ButtonDefault>
      </div>
      <div v-else>
        <ButtonDefault
          text="入力する"
          appendIcon="mdi-pencil"
          color="success"
          :fill=false
          @click="toggleInputAreaStatus(true)"/>
      </div>

      <div v-if="inputAreaStatus">
        <ButtonDefault
        text="中止する"
          appendIcon="mdi-cancel"
        color="success"
        :fill=false
        @click="cancelInputPolygon()"
          ></ButtonDefault>
      </div>
      <div v-else>
        <ButtonDefault
          text="削除する"
          appendIcon="mdi-trash-can-outline"
          color="success"
          :fill=false
          @click="clearPolygon()"
            ></ButtonDefault>
      </div>
    </v-row>
    <div style="margin-top: 35px;">
      <v-row class="row-center"
      style="
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: flex-end;">
        <v-col cols="auto" style="padding: 0px 5px;">
          <v-icon size=20px color="transparent"></v-icon>
        </v-col>
        <v-col cols="auto" class="col-center">
          <ButtonCalculation :disabled="!calActivate" :isUpload="false"/>
        </v-col>
        <v-col cols="auto" class="col-center" style="display: flex; align-content: end; justify-content: flex-start;">
          <ButtonHelp/>
        </v-col>
      </v-row>
    </div>
    
    <TableFormPoints
      :tableWidthMini="isPolygon">
    </TableFormPoints>
    
    <h1 class="sidebar_subhead" style="margin-top:18px;"></h1>
    <div class="site-condition">
      <v-card flat style="font-size:12px;">
        <v-row>
          <v-col>
            <div v-if="getAreaZoneOverFlg" class="text-left allow-line-break">
              <p class="error-message">{{ getErrorMessage() }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import ButtonDefault from '@/components/Button/ButtonDefault.vue';
import ButtonCalculation from '@/components/Button/ButtonCalculation.vue';
import ButtonHelp from '@/components/Button/ButtonHelp.vue';
import TableFormPoints from '@/components/TableFormPoints.vue';

import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'tab-pickpoints',
  components: {
    ButtonDefault,
    ButtonCalculation,
    ButtonHelp,
    TableFormPoints
  },
  data () {
  },
  watch: {
    polygonVertices(newValue) {
      this.setPolygonVertices(newValue);
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  computed: {
    ...mapState('DrawPolygonStatus', {
      inputAreaStatus: (state) => state.inputAreaStatus,
      inputCancelFlg: (state) => state.inputCancelFlg,
      calActivate: (state) => state.calActivate,
      polygonVertices: (state) => state.polygonVertices,
    }),
    ...mapGetters('ConditionStatus', ['getAreaZoneOverFlg']),
    isPolygon(){
      return this.polygonVertices.length > 0;
    },
    updateSidebarWidth() {
      return{
        width: '60vw', //0 < this.polygonVertices.length ? '65vw' : '25vw',
      };
    },
  },
  methods: {
    ...mapActions('DrawPolygonStatus', ['setInputAreaStatus', 'setInputCancelFlg', 'setPolygonVertices', 'setCalActiveFlg']),
    toggleInputAreaStatus(status) {
      this.setInputAreaStatus(status); // 入力中かどうか
    },
    clearPolygon() {
      if (this.inputAreaStatus === true) {
        this.toggleInputAreaStatus(false)
      } else {
        this.setPolygonVertices([])
      }
    },
    cancelInputPolygon() {
      this.setInputCancelFlg(true)
      this.toggleInputAreaStatus(false)
    },
    getErrorMessage() {
      return 'エラー\n敷地が用途地域をまたがっている為、計算ができません';
    },
  },
}
</script>

<style scoped>
.row-center {
  justify-content: center;
  align-items: center;
}
.col-center {
  padding: 0px 5px;
}
button.v-btn[disabled] {
  opacity: 0.6;
}
.site-condition{
  padding: 0px;
}
.allow-line-break {
  white-space: pre-line;
}
.error-message {
  font-weight: bold;
  color: #F60707;
}
</style>