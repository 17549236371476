<template>
  <div class="about-page">
  <h1 class="heading">高さ制限.com(β)について</h1>
  <p class="paragraph">『高さ制限.com（β）』は、<a href="https://archiroid.notion.site/archiroid-d7e1111b7ebe4f058d061c1694eb2944" target="_blank" rel="noopener noreferrer" class="custom-link">株式会社アーキロイド</a>が運営する、建築の高さ制限（絶対高さ、斜線制限、逆日影）を３Dで確認するためのウェブサイトです。</p>
  <p class="paragraph">敷地情報を指定の<router-link to="/format-sample"  class="custom-link">フォーマット</router-link>に入力しアップロードすると、WEB上で３D形状の確認と、Rhinocerosモデルデータ（.3dm）をダウンロードすることができます。</p>
  <p class="paragraph">現在、期間限定で無料のβサービスを公開しております。サービスの詳しい利用方法は<router-link to="/how-to-use"  class="custom-link">使い方</router-link>をご覧ください。</p>
  <p class="paragraph">なお、当サイトのサービスは、必ずしも正確性、妥当性、最新性、利用者に適用ある法令への適合を保証するものではありません。当サイトの利用については各自自己責任においてご活用ください。
当サイトの利用に際しては、必ず<router-link to="/site-policy"  class="custom-link">サイトポリシー</router-link>をご確認の上ご利用ください。</p>
  <p class="paragraph">サービスに関するお問い合わせ、ご質問、改善のご要望、誤りのご指摘などございましたら、<router-link to="/contact"  class="custom-link">コンタクト</router-link>より連絡をお願いします。</p>
  <p class="end">株式会社アーキロイド</p>
</div>
</template>

<script>

</script>

<style scoped>
.about-page {
  margin-top: 20vh;
  margin-left: 20vw;
  margin-right: 20vw;
}

.heading {
margin-bottom: 10vh;
}

.paragraph {
margin-top: 20px;
}
.paragraph .custom-link {
color: #000000;
}

.end{
  margin-top: 10vh;
  text-align: right;
}
</style>