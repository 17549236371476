<template>
  <div>
    <v-navigation-drawer
      location="right"
      justify-content="center"
      permanent
      floating
      :style="{ width: drawerWidth }"
    >
      <v-tabs
        v-model="tab"
        rounded
        :touchless="true"
        align-tabs="center"
        style="display: block"
      >
        <v-tab
          v-for="(ta, index) in tabItems"
          :key="ta.name"
          rounded
          class="tab_menu_item"
          @click="changeTab(index)"
        >
          <span class="tab_text">{{ta.name}}</span>
        </v-tab>
      </v-tabs>
      <v-window v-model="tabIndex">
        <div v-if="tabIndex === 0">
          <TabPickPoints/>
        </div>
        <div v-else-if="tabIndex === 1">
          <TabUpload/>
        </div>
      </v-window>
    </v-navigation-drawer>
  </div>
</template>

<script>
import TabPickPoints from './TabPickPoints.vue';
import TabUpload from './TabUpload.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabBar',
  components: {
    TabPickPoints,
    TabUpload
  },
  watch: {
    miniFlg (newValue, oldValue){
      console.log(newValue, oldValue);
    },
  },
  data () {
    return {
      tab: 0,
      tabWidth: '60vw', //'30vw'
      tabItems: [
        { name:'　　作成する　　', link:'', icon:'', route:'Input', index:0 },
        { name:'アップロードする', link:'', icon:'', route:'Upload', index:1 },
      ],
      tabIndex: 0,
    }
  },
  created: function () {
  },
  computed: {
    ...mapState('DrawPolygonStatus', {
      polygonVertices: (state) => state.polygonVertices,
    }),
    drawerWidth() {
      return this.tabIndex === 0 ? '60vw' : '100vw';
    }
  },
  mounted: function () {
  },
  methods: {
    ...mapActions('SiteStyleStatus', ['setInputStyleStatus']),
    changeTab: function (index) {
      this.tabIndex = index
      this.setInputStyleStatus(index)
      this.setTabWidth()
    },
  }
}
</script>

<style>
h1.sidebar_subhead {
  color: #878787;
  font-size: 16px;
  font-weight: bold;
  padding-top: 0px;
  padding-left: 0px;
}
</style>

<style scoped>
::v-deep ::-webkit-scrollbar {
  width: 1;
}
</style>