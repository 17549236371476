<template>
    <div>
        <p>This is Test Page FormConfirm</p>
        <p>This is Test Page FormConfirm</p>
        <p>This is Test Page FormConfirm</p>
        <p>This is Test Page FormConfirm</p>
        <p>This is Test Page FormConfirm</p>
        <ButtonDefault
            color="success"
            text="戻る"
            @click="clickBack"
            fill=false
        ></ButtonDefault>
        <ButtonDefault
            color="success"
            text="完了"
            @click="clickCompleted"
        ></ButtonDefault>
    </div>
</template>

<script>
    import ButtonDefault from '@/components/Button/ButtonDefault.vue';
    export default {
        name: 'ConditionInput',
            components: {
                ButtonDefault
            },
        props: {
            color: {
                type: String,
                default: 'primary',
            },
            text: {
                type: String,
                default: '',
            },
            icon: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            fill: {
                type: Boolean,
                default: true
            }
        },
        mounted: function () {
        },
        methods: {
            clickBack: function () {
                this.$router.push('condition-input');
            },
            clickCompleted: function () {
                console.log("clickCompleted");
            }
        }
    };
</script>

<style scoped>
/* ここに必要なスタイルを追加 */
</style>