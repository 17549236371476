<template>
  <v-app-bar
    color="white"
    flat
    app
    >
    <v-row align="center">
      <v-col cols="auto" class="pa-12">
        <v-img 
          height=50
          width=271.12
          :src="logo"
          @click="navigateTo('/')"  
          />
      </v-col>
      <!-- <div style="width: 20vw;"></div> -->
      <v-col>
        <v-btn
          v-for="hm in headMenues"
          :key="hm.text"
          text
          class="headerbutton"
          @click="navigateTo(hm.link)"         
        >
          {{ hm.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      barHight: 64,
      headMenues: [
        {
          text: 'サービスについて',
          link: 'about-service'
        },
        {
          text: '使い方',
          link: 'how-to-use'
        },
        {
          text: 'フォーマットとサンプル',
          link: 'format-sample'
        },
        {
          text: 'サイトポリシー',
          link: 'site-policy'
        },
        {
          text: 'コンタクト',
          link: 'contact'
        }
      ],
      logo: require('../assets/images/site_logo_7.png'),
    }
  },
  methods: {
    navigateTo(link) {
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
.heading {
  margin: 0;
  padding: 0px;
  background-color: 
  black;
  color: 
  white;
}
.headerbutton {
  font-size: 17px;
  font-weight: bold;
}

@media (max-width: 600px)  {
  .header {
    padding: 0 20px !important;
  }
}
</style>
