<template>
    <ButtonDefault
        color="success"
        :text="isUpload ? '計算する' : '詳細入力へ'"
        @click="clickCalculation(disabled, isUpload)"
        :disabled="disabled"
        ></ButtonDefault>
</template>
  
<script>
import ButtonDefault from '@/components/Button/ButtonDefault.vue';
export default {
  name: 'ButtonCalculation',
  components: {
      ButtonDefault
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    isUpload: { // upload:true, pickpoints:false
      type: Boolean,
      required:true,
    },
  },
  methods: {
    clickCalculation(disabled, isUpload){
      if(!disabled){
        if (isUpload) {
          alert('アップロードパターン　未実装')
          console.log('「計算する」ボタンがクリックされました', 'isUpload : ', isUpload);
        } else {
          this.$router.push('condition-input');
          console.log('「計算する」ボタンがクリックされました', 'isUpload : ', isUpload);
        }
      }
    }
  },
};
</script>

<style scoped>
</style>
