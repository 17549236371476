const DrawPolygonStatus = {
  namespaced: true,
  state: {
    inputAreaStatus: false,
    inputCancelFlg: false,
    calActivate: false,

    polygonVertices: [], // Array to store polygon vertices
    polygonCentroid: [139.753, 35.6844],
    selectedLineNo: 0,
    
    roadLengths: [],
    roadWidths: [],
    roadHeights1: [],
    roadHeights2: [],
    neighborHeights: [],
    setbacks: [],
    setbacks2: [],
    openSpaceDistances: []
  },
  mutations: {
    SET_INPUT_AREA_STATUS(state, status) {
      state.inputAreaStatus = status;
    },
    SET_INPUT_CANCEL_FLG(state, status) {
      state.inputCancelFlg = status;
    },
    SET_CALCULATION_FLG(state, flg) {
      state.calActivate = flg;
    },
    SET_POLYGON_VERTICES(state, vertices) {
      state.polygonVertices = vertices;
    },
    SET_POLYGON_CENTROID(state, centroid) {
      state.polygonCentroid = centroid;
    },
    SET_SELECTED_LINE_NO(state, number) {
      state.selectedLineNo = number;
    },
    SET_ROAD_LENGTHS(state, lengths) {
      state.roadLengths = lengths;
    },
    SET_ROAD_WIDTHS(state, payload) {
      // 全体のリストを更新
      if (Array.isArray(payload)) {
        state.roadWidths = payload;
      }
      // 特定のアイテムのみを更新
      else if (typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload,'no')) {
        state.roadWidths[payload.no] = payload.value;
      }
    },
    SET_ROAD_HEIGHTS1(state, payload) {
      if(Array.isArray(payload)){
        state.roadHeights1 = payload;
      }
      else if(typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload,'no')){
        state.roadHeights1[payload.no] = payload.value;
      }
    },
    SET_ROAD_HEIGHTS2(state, payload) {
      if(Array.isArray(payload)){
        state.roadHeights2 = payload;
      }
      else if(typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload,'no')){
        state.roadHeights2[payload.no] = payload.value;
      }
    },
    SET_NEIGHBOR_HEIGHTS(state, payload) {
      if(Array.isArray(payload)){
        state.neighborHeights = payload;
      }
      else if(typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload,'no')){
        state.neighborHeights[payload.no] = payload.value;
      }
    },
    SET_SETBACKS(state, payload) {
      if(Array.isArray(payload)){
        state.setbacks = payload;
      }
      else if(typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload,'no')){
        state.setbacks[payload.no] = payload.value;
      }
    },
    SET_SETBACKS2(state, payload) {
      if(Array.isArray(payload)){
        state.setbacks2 = payload;
      }
      else if(typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload,'no')){
        state.setbacks2[payload.no] = payload.value;
      }
    },
    SET_OPENSPACEDISTANCES(state, payload) {
      if(Array.isArray(payload)){
        state.openSpaceDistances = payload;
      }
      else if(typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload,'no')){
        state.openSpaceDistances[payload.no] = payload.value;
      }
    },
  },
  actions: {
    setInputAreaStatus({ commit }, status) {
      commit('SET_INPUT_AREA_STATUS', status);
    },
    setInputCancelFlg({ commit }, status) {
      commit('SET_INPUT_CANCEL_FLG', status);
    },
    setCalActiveFlg({ commit }, flg) {
      commit('SET_CALCULATION_FLG', flg);
    },
    setPolygonVertices({ commit }, vertices) {
      commit('SET_POLYGON_VERTICES', vertices);
    },
    setPolygonCentroid({ commit }, centroid) {
      commit('SET_POLYGON_CENTROID', centroid);
    },
    setSelectedLineNo({ commit }, number) {
      commit('SET_SELECTED_LINE_NO', number);
    },
    setRoadLengths({ commit }, lengths) {
      commit('SET_ROAD_LENGTHS', lengths);
    },
    setRoadWidths({ commit }, widths) {
      commit('SET_ROAD_WIDTHS', widths);
    },
    setRoadHeights1({ commit }, heights) {
      commit('SET_ROAD_HEIGHTS1', heights);
    },
    setRoadHeights2({ commit }, heights) {
      commit('SET_ROAD_HEIGHTS2', heights);
    },
    setNeighborHeights({ commit }, heights) {
      commit('SET_NEIGHBOR_HEIGHTS', heights);
    },
    setSetbacks({ commit }, setbacks) {
      commit('SET_SETBACKS', setbacks);
    },
    setSetbacks2({ commit }, setbacks2) {
      commit('SET_SETBACKS2', setbacks2);
    },
    setOpenSpaceDistances({ commit }, distances) {
      commit('SET_OPENSPACEDISTANCES', distances);
    },
  },
  getters: {
    getPolygonVertices(state) {
      return state.polygonVertices;
    },
    getPolygonCentroid(state) {
      return state.polygonCentroid;
    },
  },
};

export default DrawPolygonStatus;