<template>
    <v-btn
    :size="iconButtonSize"
    elevation="0"
    color="transparent"
    >
        <v-icon
        size=35px
        :color="color"
        :icon="icon"></v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'ButtonIcon',
    data () {
        return {
            iconButtonSize: "40px 40px",
        }
    },
    props: {
        color: {
            type: String,
            default: 'white',
        },
        icon: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        prependIcon: {
            type: String,
            default: ''
        },
        iconSize: {
            type: String,
            default: ''
        }
    },
};
</script>

<style scoped>
</style>
